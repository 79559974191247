import common from "../common";
import Scene from "./Scene";
import {translate} from "../utils";
//import MultiStyleText from "pixi-multistyle-text";
import LoadingIndicator from "../ui/LoadingIndicator";

export default class SplashScene extends Scene {
    constructor() {
        super();
    }

    init() {
        super.init();

        this._bg = new PIXI.Sprite(P3.assets.texture("splash_bg"));
        this._bg.width = common.STAGE_WIDTH;
        this._bg.height = common.STAGE_HEIGHT;
        this._bg.interactive = true;
        this._bg.mousedown = this._bg.touchstart = () => this.end();
        this.addChild(this._bg);

        this._playButton = P3.Button.create(
            P3.assets.texture("btn_large_out"),
            P3.assets.texture("btn_large_over"),
            P3.assets.texture("btn_large_down")
        );
        this._playButton.clickSoundName = "sfx_click";
        this._playButton.overSoundName = "sfx_rollover";
        this._playButton.position.set(common.STAGE_WIDTH * 0.5, common.STAGE_HEIGHT * 0.5 + 150);
        this._playButton.signals.click.once(this.onPlayButtonClick, this);
        this._playButton.visible = false;
        this.addChild(this._playButton);

        this._playButton.playIcon = new PIXI.Sprite(P3.assets.texture("icon_play"));
        this._playButton.playIcon.anchor.set(0.5);
        this._playButton.playIcon.x = this._playButton.width * 0.5 - 60;
        this._playButton.animation.addChild(this._playButton.playIcon);

        this._playButton.label = new PIXI.Text(translate("play"),
            {fontFamily: "oswaldregular", fontSize: 58, fill: 0x0, fontWeight: "bold"});
        this._playButton.label.anchor.set(0.5);
        this._playButton.label.x = -24;
        this._playButton.label.y = 2;
        this._playButton.animation.addChild(this._playButton.label);

        this._blackStrip = new PIXI.Sprite(PIXI.Texture.WHITE);
        this._blackStrip.tint = 0x0;
        this._blackStrip.anchor.set(0.5, 1);
        this._blackStrip.width = common.STAGE_WIDTH;
        this._blackStrip.height = 110;
        this.addChild(this._blackStrip);

        this._logoFirstCar = new PIXI.Sprite(P3.assets.texture("logo_first_car"));
        this._logoFirstCar.anchor.set(0.5);
        //this.addChild(this._logoFirstCar);

        this._logoBHS = new PIXI.Sprite(P3.assets.texture("logo_bhs"));
        this._logoBHS.anchor.set(0.5);
        this.addChild(this._logoBHS);

        this._logoPolice = new PIXI.Sprite(P3.assets.texture("logo_police"));
        this._logoPolice.anchor.set(0.5);
        this.addChild(this._logoPolice);

        this._footerLabel = new PIXI.HTMLText(translate("footer"),
                {
                    fontFamily: "oswaldregular",
                    fontSize: 20,
                    fill: "#ffffff",
                    align: "center",
                    // wordWrap: true,
                    //wordWrapWidth: 720
                });
        this._footerLabel.style.loadFont('assets/fonts/oswald-variablefont_wght-webfont.woff', { family: 'oswaldregular' }).then(() => {
            
        });
        $(this._footerLabel._shadow).css('zIndex', '-2000');

        /*this._footerLabel = new MultiStyleText(translate("footer"),
            {
                "default": {
                    fontFamily: "oswaldregular",
                    fontSize: 20,
                    fill: "#ffffff",
                    align: "center",
                    // wordWrap: true,
                    //wordWrapWidth: 720
                },
                "r": {
                    fontFamily: "oswaldregular",
                    fontSize: 20,
                    fill: "#e20200",
                    align: "center",
                    //wordWrap: true,
                    // wordWrapWidth: 720
                }
            });*/

        this._footerLabel.interactive = true;
        this._footerLabel.buttonMode = true;
        this._footerLabel.mousedown = this._footerLabel.touchstart = this._onFooter.bind(this);
        //= new PIXI.Text(translate("footer"),
        // {fontFamily: "oswaldregular", fontSize: 24, fill: 0xffffff});

        this._footerLabel.anchor.set(0.5);
        this.addChild(this._footerLabel);

    }

    resize() {
        super.resize();

        this._blackStrip.position.set(common.STAGE_WIDTH * 0.5, common.STAGE_HEIGHT);
        this._footerLabel.position.set(common.STAGE_WIDTH * 0.5, common.STAGE_HEIGHT - this._blackStrip.height * 0.5);

        this._logoPolice.position.set((common.STAGE_WIDTH - P3.View.width) * 0.5 + this._logoPolice.width * 0.5 + 40, common.STAGE_HEIGHT - this._blackStrip.height * 0.5);
        this._logoBHS.position.set((common.STAGE_WIDTH + P3.View.width) * 0.5 - this._logoBHS.width * 0.5 - 40, common.STAGE_HEIGHT - this._blackStrip.height * 0.5);

    }

    animateIn() {
        this._playButton.alpha = 0.3;
        new TWEEN.Tween(this._playButton, this._tweens)
            .delay(190)
            .onStart(() => this._playButton.visible = true)
            .to({alpha: 1}, 210)
            .easing(TWEEN.Easing.Quadratic.Out)
            .start();
    }

    destroy() {
        // clean up goes here
        super.destroy();
    }

    update() {
        super.update();
    }

    end() {
        this._bg.interactive = false;
        this.signals.next.dispatch();
    }

    onPlayButtonClick() {
        this.end();
    }

    _onFooter() {
        window.open(common.config.privacy_policy);
    }
}