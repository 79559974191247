import common from "../common";

/**
 * @abstract @class BasePanel - all panels should extend this struct
 */
export default class BasePanel extends PIXI.Container {

    set title(value) {
        this._titleLabel.text = value;
    }

    constructor(texture, options = {leftWidth: 32, topHeight: 32, rightWidth: 32, bottomHeight: 32, fontSize: 58}) {
        super();
        this._slice = new PIXI.NineSlicePlane(typeof texture === "string" ? P3.assets.texture(texture) : texture, options.leftWidth, options.topHeight, options.rightWidth, options.bottomHeight);
        this._slice.alpha = common.config.debug ? 0.5 : 1;
        this.addChild(this._slice);

        this._titleLabel = new PIXI.Text("", {
            fontFamily: "oswaldregular",
            fontSize: options.fontSize,
            // fill: 0xffcf10,
            fill: 0xffffff,
            dropShadow: true,
            dropShadowAngle: 0.5,
            dropShadowBlur: 5,
            dropShadowColor: 0x0
        });

        this._titleLabel.anchor.set(0.5);
        this.addChild(this._titleLabel);
    }

    updateDimensions(width, height) {
        this._slice.width = width;
        this._slice.height = height;
        this._slice.pivot.set(this._slice.width * 0.5, this._slice.height * 0.5);
        this._titleLabel.position.set(0, -this._slice.height * 0.5 + 70);
        //this._titleLabel.position.set(0, -this._slice.height * 0.5 + this._titleLabel.height * 0.5 - 60);
    }
}