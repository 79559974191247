import {translate} from "../utils";
import common from "../common";

export default class HazardWheel extends PIXI.Container {
    constructor() {
        super();

        this._total = 25;

        this.signals = {};
        this.signals.reveal = new P3.utils.MiniSignal();

        this._segments = [];

        this._label = new PIXI.Text("", {
            fontFamily: "oswaldregular",
            fontSize: 52,
            fill: 0x0,
            dropShadow: true,
            dropShadowAngle: 0.5,
            dropShadowBlur: 5,
            dropShadowColor: 0xd3d3d3
        });
        this._label.anchor.set(0.5);
        this._label.y = 2;
        this.addChild(this._label);

        this.initWheel(85);
        this.setLabel(0);
    }

    destroy(options) {
        super.destroy(options);
    }

    setLabel(value) {
        this._label.text = value + "/" + this._total;
    }

    initWheel(radius) {

        const total = this._total;

        const step = (Math.PI * 2) / total;
        let theta = -Math.PI * 0.5 + step * 0.5;

        for (let i = 0; i < total; i++) {
            const segment = new PIXI.Sprite(P3.assets.texture("hazards_ring_section_wrong"));
            segment.anchor.set(0.5);
            segment.position.set(Math.cos(theta) * radius, Math.sin(theta) * radius);
            this.addChild(segment);

            segment.rotation = theta + Math.PI * 0.5;

            theta += step;

            this._segments.push(segment);
        }

    }

    animateResult(total) {
        const delay = 0.06;
        for (let i = 0; i < total; i++) {
            const segment = this._segments[i];
            common.delay(i * delay, () => (segment.texture = P3.assets.texture("hazards_ring_section_correct")) && this.setLabel(i + 1));
        }

        common.delay(total * delay, () => this.signals.reveal.dispatch());
    }
}