import {translate} from "../utils";

export default class MistakeWarning extends PIXI.Container {
    constructor() {
        super();

        this._tweens = new TWEEN.Group();

        this._label = new PIXI.Text(translate("warning"), {fontFamily: "oswaldregular", fontSize: 58, fill: 0xffffff});
        this._label.anchor.set(0.5);
        this.addChild(this._label);

        this.visible = false;

    }

    update() {
        this._tweens.update();
    }

    show() {
        this.visible = true;
        this.alpha = 0.3;
        this.scale.set(0.3);
        new TWEEN.Tween(this, this._tweens)
            .to({alpha: 1}, 110)
            .easing(TWEEN.Easing.Quadratic.In)
            .start();
        new TWEEN.Tween(this.scale, this._tweens)
            .to({x: 1, y: 1}, 140)
            .easing(TWEEN.Easing.Quadratic.Out)
            .start();
    }

    hide() {
        new TWEEN.Tween(this, this._tweens)
            .to({alpha: 0.3}, 240)
            .easing(TWEEN.Easing.Quadratic.In)
            .onComplete(() => this.visible = false)
            .start();
        new TWEEN.Tween(this.scale, this._tweens)
            .to({x: 0.3, y: 0.3}, 280)
            .easing(P3.utils.Easing.Back2.In)
            .start();
    }
}