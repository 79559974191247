export default {
    /**
     * @static
     * @type {number}
     * @default 1792
     */
    STAGE_WIDTH: 1792,

    /**
     * @static
     * @type {number}
     * @default 768
     */
    STAGE_HEIGHT: 768,

    /**
     * @static
     * @type {object}
     */
    config: {},

    /**
     * @static
     * @type {PIXI.CanvasRenderer|PIXI.Renderer}
     */
    renderer: null,

    /**
     * @static
     * @type {PIXI.Container}
     */
    stage: null,

    /**
     * @static
     * @type {P3.Ticker}
     */
    ticker: null,

    /**
     * @static
     * @type {PIXI.Container}
     */
    transitions: null,

    /**
     * @static
     * @type {string}
     */
    videoFolder: "",

    /**
     * @static
     * @type {?object}
     */
    strings: null,

    /**
     * @static
     * @type {array}
     */
    foundHazards: [],

    /**
     * @static
     * @type {number}
     */
    score: -1,

    /**
     * @static
     * @type {number}
     */
    sceneScore: -1,

    /**
     * @static
     * @type {array<VideoCache>}
     */
    videoCache: [],

    playerId: null,

    delay: function(duration, callback, args, scope) {
        return this.delayg(TWEEN, duration, callback, args, scope);
    },
    
    /**
     * @param {TWEEN.Group} group
     * @param {number} duration
     * @param {function} callback
     * @param {Array} [args]
     * @param {*} [scope]
     * @returns {TWEEN.Tween|TweenMax}
     */
    delayg: function(group, duration, callback, args, scope) {
        scope = scope || window;

        return new TWEEN.Tween({}, group)
            .to({}, duration * 1000)
            .onComplete(() => {
                callback.apply(scope, args);
            })
            .start();
    }
}