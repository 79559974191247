import {translate} from "../utils";
import * as Particles from "pixi-particles";

export default class ScoreStrip extends PIXI.Container {

    get score() {
        return parseInt(this._label.text);
    }

    set score(value) {
        if (typeof value === "number")
            this._label.text = "" + value;
    }

    constructor() {
        super();

        this._strip = new PIXI.Sprite(P3.assets.texture("score"));
        this._strip.anchor.set(0.5);
        this.addChild(this._strip);

        this._stripWrongHighlight = new PIXI.Sprite(P3.assets.texture("score"));
        this._stripWrongHighlight.anchor.set(0.5);
        this._stripWrongHighlight.tint = 0xde121c;
        this._stripWrongHighlight.alpha = 0;
        this.addChild(this._stripWrongHighlight);

        this._label = new PIXI.Text("",
            {fontFamily: "oswaldregular", fontSize: 50, fill: 0x0, fontWeight: "bold"});
        this._label.anchor.set(0.5);
        this._label.x = this._strip.width * 0.25 - 8;
        this._label.y = 2;// dodgy font
        this.addChild(this._label);

        this._titleLabel = new PIXI.Text(translate("score_game"),
            {fontFamily: "oswaldregular", fontSize: 24, fill: 0x0, fontWeight: "bold"});
        this._titleLabel.anchor.set(0.5);
        this._titleLabel.x = -this._strip.width * 0.25 - 22;
        this._titleLabel.y = 2;
        this.addChild(this._titleLabel);

        this._emitter = new Particles.Emitter(this, [
                P3.assets.texture("particle_star")],
            P3.assets.json("particle_click_star_burst")
        );
        this._emitter.emit = false;

    }

    update(dt) {
        this._emitter.update(dt);
    }

    correct() {
        const a = new TWEEN.Tween(this.scale)
            .to({x: 1.1, y: 1.1}, 140)
            .easing(TWEEN.Easing.Quadratic.Out);

        const b = new TWEEN.Tween(this.scale)
            .to({x: 1, y: 1}, 220)
            .easing(TWEEN.Easing.Back.In);

        a.chain(b);
        a.start();

        P3.audio.playSound("sfx_positive");
        this._emitter.emit = true;
    }

    wrong() {
        P3.audio.playSound("sfx_negative");

        this._stripWrongHighlight.alpha = 0;
        new TWEEN.Tween(this._stripWrongHighlight)
            .to({alpha: 1}, 140)
            .easing(TWEEN.Easing.Quadratic.In)
            .onComplete(() => {
                new TWEEN.Tween(this._stripWrongHighlight)
                    .to({alpha: 0}, 140)
                    .easing(TWEEN.Easing.Quadratic.In)
                .start();
            })
            .start();

        
    }

    show() {
        this.visible = true;
        this.alpha = 1;
    }

    hide() {
        new TWEEN.Tween(this)
            .to({alpha: 0.3}, 140)
            .easing(TWEEN.Easing.Quadratic.In)
            .onComplete(() => this.visible = false)
            .start();
    }

}