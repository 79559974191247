import common from "../common";
import Scene from "./Scene";
import ScoreStrip from "../ui/ScoreStrip";
import BonusQuestionPanel from "../ui/BonusQuestionPanel";
import {queueVideos, translate} from "../utils";

export default class BonusScene extends Scene {
    constructor(questionNumber) {
        super();

        this._questionData = P3.assets.json("questions")["bonus_" + questionNumber];

        this._questionNumber = questionNumber;

    }

    init() {
        super.init();

        this._bg = new PIXI.Sprite(P3.assets.texture("intro_bg"));
        this._bg.width = common.STAGE_WIDTH;
        this._bg.height = common.STAGE_HEIGHT;
        this.addChild(this._bg);

        this._panel = new BonusQuestionPanel(this._questionData.question, this._questionData.answers);
        this._panel.position.set(common.STAGE_WIDTH * 0.5, common.STAGE_HEIGHT * 0.5);
        this._panel.scale.set(0.8);
        this._panel.visible = false;
        this.addChild(this._panel);

        this._panel.signals.answered.once(this.onAnswer, this);

        this._panel.startTimer(15);

        this._scoreStrip = new ScoreStrip();
        this._scoreStrip.position.set(common.STAGE_WIDTH * 0.5, common.STAGE_HEIGHT - 40);
        this._scoreStrip.score = common.score;
        this.addChild(this._scoreStrip);

        // todo - find a better fix for this
        if (this._questionNumber === 1) {
            this._panel.questionHolder.y -= 80;
            this._panel.scale.set(0.75);//todo
        }

        this._strip = new PIXI.Sprite(P3.assets.texture("top_bar"));
        this._strip.width = common.STAGE_WIDTH;
        this._strip.anchor.set(0.5);
        this.addChild(this._strip);

        this._titleLabel = new PIXI.Text(translate("bonus"), {
            fontFamily: "oswaldregular",
            fontSize: 34,
            fill: 0x0,
            dropShadow: true,
            dropShadowAngle: 0.5,
            dropShadowBlur: 5,
            dropShadowColor: 0xd3d3d3
        });
        this._titleLabel.anchor.set(0.5);
        this.addChild(this._titleLabel);

    }

    resize() {
        super.resize();

        this._strip.position.set(common.STAGE_WIDTH * 0.5, this._strip.height * 0.5);
        this._titleLabel.position.set(common.STAGE_WIDTH * 0.5, this._strip.height * 0.5 + 4);

    }

    animateIn() {
        this._panel.alpha = 0;
        new TWEEN.Tween(this._panel, this._tweens)
            .onStart(() => this._panel.visible = true)
            .to({alpha: 1}, 310)
            .easing(TWEEN.Easing.Quadratic.In)
            .start();
    }

    destroy() {
        // clean up goes here
        super.destroy();
    }

    update(dt = window.deltaTime) {
        super.update();
        this._panel.update(dt);
    }

    onAnswer(button) {
        if (button.correct > -1) {
            common.score += common.config.scoring.bonus_question;
            this._scoreStrip.score = common.score;
        }
        queueVideos(this._questionNumber + 1);
        common.delayg(this._tweens, 3, () => this.signals.next.dispatch());
    }
}