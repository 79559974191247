//import TextInput from "pixi-text-input";
import * as PIXI from 'pixi.js-legacy';

import HTMLText from '../libs/pixi-text-html.js';
PIXI.settings.ADAPTER = {
    getNavigator: () => {
        return window.navigator.userAgent;
    },
    fetch: (url, options) => {
        return fetch(url, options);
    },
    createCanvas: (width, height) => {
        var canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        return canvas;
    }
};
PIXI.utils.path = {
    basename: (url, ext) => {
        return url.split('/').pop();
    },
    extname: (url) => {
        return url.split('.').pop();
    }
};

//import jquery from 'jquery';
import jquery from "../libs/jquery-3.4.1.min.js";
global.$ = jquery;

//import TWEEN from '@tweenjs/tween.js';
import TWEEN from '../libs/tween.js';
global.TWEEN = TWEEN;

import * as P3 from "p3";
//import "../../libs/p3.min";

import Main from './Main';

const main = new Main(1024, 768);
main.init();
