import BasePanel from "./BasePanel";
import {createLabelButton, translate} from "../utils";
import TickBox from "./TickBox";
import Shake from "../utils/Shake";
import common from "../common";

export default class DetailsPanel extends BasePanel {

    static validateEmail(email) {
        return (/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(email));
    }

    get details() {
        return {
            name: this._nameInput.text,
            email: this._emailInput.text,
            birthyear: parseInt(this._yearInput.text),
            postcode: this._postcodeInput.text,
            marketing: this._tickBox.on,
            score: Math.abs(common.score),
        };
    }

    constructor() {
        super(PIXI.Texture.WHITE, {leftWidth: 4, topHeight: 4, rightWidth: 4, bottomHeight: 4, fontSize: 58});
        this.updateDimensions(800, 420);

        this.signals = {};
        this.signals.submitted = new P3.utils.MiniSignal();

        this._nameInput = this._createInput(translate("name"), 680);
        this._nameInput.maxLength = 30;//todo check good value / add to config
        this.addChild(this._nameInput);

        this._emailInput = this._createInput(translate("email"), 680);
        this._emailInput.maxLength = 40;//todo check good value / add to config
        this.addChild(this._emailInput);

        this._emailInput.on('blur', () => {
            if (!DetailsPanel.validateEmail(this._emailInput.text)) {
                // invalid email
                this._emailInput.disabled = true;
                this._shake.trauma = 1;
                this._shake.onShakeEnd = () => {
                    this._shake.onShakeEnd = null;
                    this._emailInput.text = "";
                    this._emailInput.disabled = false;
                }
            }
        });

        this._yearInput = this._createInput(translate("year"), 330);
        this._yearInput.maxLength = 4;//todo check good value / add to config
        this._yearInput.restrict = "0123456789";
        this.addChild(this._yearInput);

        this._postcodeInput = this._createInput(translate("postcode"), 330);
        this._postcodeInput.maxLength = 4;//todo check good value / add to config
        this.addChild(this._postcodeInput);

        this._postcodeInput.on('keydown', keycode => {
            if (keycode === 13)
                input.blur();
        });

        this._postcodeInput.on('keyup', keycode => {
            this._postcodeInput.text = this._postcodeInput.text.toUpperCase();
        });

        this._tickBox = new TickBox(P3.assets.texture("tickbox"), P3.assets.texture("tickbox_tick"));
        this._tickBox.on = false;
        this.addChild(this._tickBox);

        this._promotionsLabel = new PIXI.Text(translate("promotions"), {
            fontFamily: "oswaldregular",
            fontSize: 24,
            fill: 0x0,
            fontWeight: "bold",
            wordWrap: true,
            wordWrapWidth: 420
        });
        this._promotionsLabel.anchor.set(0, 0.5);
        this.addChild(this._promotionsLabel);

        this._nameInput.position.set(0, -140);
        this._emailInput.position.set(0, -70);

        this._yearInput.position.set(-175, 0);
        this._postcodeInput.position.set(175, 0);

        this._tickBox.position.set(-340, 110);
        this._promotionsLabel.position.set(this._tickBox.x + 40, this._tickBox.y);

        this._submitButton = createLabelButton("competition", translate("submit"));
        this._submitButton.position.set(280, 110);
        this._submitButton.signals.click.add(this._onSubmit, this);
        this.addChild(this._submitButton);

        this._termsLabel = new PIXI.Text(translate("terms"), {
            fontFamily: "oswaldregular",
            fontSize: 18,
            fill: 0x0,
            fontWeight: "bold",
        });
        this._termsLabel.interactive = true;
        this._termsLabel.buttonMode = true;
        this._termsLabel.mousedown = this._termsLabel.touchstart = this._onTerms.bind(this);
        this.addChild(this._termsLabel);

        this._termsLabel.position = this._promotionsLabel.position.clone();
        this._termsLabel.y += 50;

        this._line = new PIXI.Sprite(PIXI.Texture.WHITE);
        this._line.tint = 0x0;
        this._line.width = this._termsLabel.width;
        this._line.height = 2;
        this.addChild(this._line);

        this._line.position = this._termsLabel.position.clone();
        this._line.y += 18;

        this._shake = new Shake();
        this._shake.setTarget(this._emailInput);
        this._shake.traumaFade = 1.8;
        this._shake.maxOffsetX = 4;
        this._shake.maxOffsetY = 2;

    }

    destroy(options) {
        super.destroy(options);
        this.signals.submitted.detachAll();
    }

    update(dt) {
        this._shake.update(dt);
    }

    _createInput(placeholder, width) {
        const input = new PIXI.TextInput({
            input: {
                fontFamily: "oswaldregular",
                fontSize: '24pt',
                padding: '5px',
                width: width + 'px',
                //color: '#26272E'
            },
            box: {
                default: {fill: 0xEEEEEE},
                focused: {fill: 0xE1E3EE},
                disabled: {fill: 0xE1E3EE, stroke: {color: 0xff0000, width: 2}}
            }
        });
        input.placeholder = placeholder;
        input.pivot.set(input.width * 0.5, input.height * 0.5);

        input.on('keydown', keycode => {
            if (keycode === 13)
                input.blur();
        });

        return input;
    }

    _onSubmit() {
        this.signals.submitted.dispatch();
    }

    _onInput() {

    }

    _onTerms() {
        window.open(common.config.terms_url);
    }
}