import common from "../common";
import Scene from "./Scene";
import {queueVideos, translate} from "../utils";
import IntroPanel from "../ui/IntroPanel";
import IntroCarousel from "../ui/IntroCarousel";

export default class IntroScene extends Scene {
    constructor() {
        super();

        this._index = 0;

    }

    init() {
        super.init();

        this._bg = new PIXI.Sprite(P3.assets.texture("intro_bg"));
        this._bg.width = common.STAGE_WIDTH;
        this._bg.height = common.STAGE_HEIGHT;
        this.addChild(this._bg);

        this._panel = new IntroPanel();
        this._panel.position.set(common.STAGE_WIDTH * 0.5, common.STAGE_HEIGHT * 0.5);
        this.addChild(this._panel);

        this._panel.title = translate("instructions");
        this._panel.visible = false;

        this._carousel = new IntroCarousel();
        this._carousel.setPage(0);
        this._carousel.y = -25;
        this._panel.addChild(this._carousel);

        this._leftButton = P3.Button.create(
            P3.assets.texture("icon_chevron_out"),
            P3.assets.texture("icon_chevron_over"),
            P3.assets.texture("icon_chevron_down")
        );
        this._leftButton.clickSoundName = "sfx_click";
        this._leftButton.overSoundName = "sfx_rollover";
        this._leftButton.position.set(-460, 0);
        this._leftButton.signals.click.add(this.onLeft, this);
        this._leftButton.visible = false;
        this._leftButton.rotation = Math.PI;
        this._panel.addChild(this._leftButton);

        this._rightButton = P3.Button.create(
            P3.assets.texture("icon_chevron_out"),
            P3.assets.texture("icon_chevron_over"),
            P3.assets.texture("icon_chevron_down")
        );
        this._rightButton.clickSoundName = "sfx_click";
        this._rightButton.overSoundName = "sfx_rollover";
        this._rightButton.position.set(460, 0);
        this._rightButton.signals.click.add(this.onRight, this);
        this._panel.addChild(this._rightButton);

        this._playButton = P3.Button.create(
            P3.assets.texture("btn_regular_out"),
            P3.assets.texture("btn_regular_over"),
            P3.assets.texture("btn_regular_down"),
        );
        this._playButton.clickSoundName = "sfx_click";
        this._playButton.overSoundName = "sfx_rollover";
        this._playButton.position.set(common.STAGE_WIDTH * 0.5, common.STAGE_HEIGHT - 80);
        this._playButton.signals.click.once(this.onDown, this);
        this._playButton.visible = false;
        this.addChild(this._playButton);

        this._playButton.label = new PIXI.Text(translate("okay"),
            {fontFamily: "oswaldregular", fontSize: 52, fill: 0x0, fontWeight: "bold"});
        this._playButton.label.anchor.set(0.5);
        this._playButton.label.y = 2;
        this._playButton.animation.addChild(this._playButton.label);

        if(common.playerId == null)
        {
            this._carousel.visible = this._leftButton.visible = this._rightButton.visible = false;
            this._playButton.visible = true;

            this._panel.title = translate("no_id");
            this._panel._titleLabel.y += 100;
            this._playButton.y -= 200;
            this._panel.visible = true;
        }
    }

    animateIn() {
        if(common.playerId)
        {
            this._panel.alpha = 0;
            new TWEEN.Tween(this._panel, this._tweens)
                .onStart(() => this._panel.visible = true)
                .to({alpha: 1}, 310)
                .easing(TWEEN.Easing.Quadratic.Out)
                .start();
        }
    }

    destroy() {
        // clean up goes here
        super.destroy();
    }

    update() {
        super.update();
    }

    onDown(e) {
        // start all the next scenes video while we have the
        // user interaction to allow us to do so
        if(common.playerId)
        {
            queueVideos(1);

            this.signals.next.dispatch();

            const cache = common.videoCache[0];

            //make sure all the other videos are definetly stopped
            const videos = cache.all;
            for (let i = 0; i < videos.length; i++) {
                videos[i].stop();
            }
        }
        else
        {
            window.location.href = 'http://www.hazardhero.co.uk';
        }
    }

    onLeft(button) {
        if (this._index > 0)
            this._index--;

        this._carousel.setPage(this._index);
        this._leftButton.visible = this._index !== 0;
        this._rightButton.visible = this._index !== this._carousel.numPages-1;
        this._playButton.visible = !this._rightButton.visible;
    }

    onRight(button) {
        if (this._index < this._carousel.numPages-1)
            this._index++;

        this._carousel.setPage(this._index);
        this._leftButton.visible = this._index !== 0;
        this._rightButton.visible = this._index !== this._carousel.numPages-1;
        this._playButton.visible = !this._rightButton.visible;
    }
}