import common from "../common";

export default class Video extends PIXI.Container {

    static inRange(value, start, end) {
        return value >= start && value <= end;
    }

    static skipEnd(video, offset = 15) {
        video.source.currentTime = video.source.duration - offset;
    }

    get currentTime() {
        return this._source.currentTime;
    }

    get paused() {
        return this._source.paused;
    }

    get source() {
        return this._source;
    }

    get ready() {
        return this._source.readyState >= 3;
    }

    constructor(fileName, ext = "mp4", uri = "assets/videos/") {
        super();

        this.visible = false;

        const texture = PIXI.Texture.from(uri + common.videoFolder + fileName + "." + ext, {resourceOptions: {autoPlay: false}}, false);
        this._source = texture.baseTexture.resource.source;

        this._videoSprite = new PIXI.Sprite(texture);
        this._videoSprite.position.set(common.STAGE_WIDTH * 0.5, common.STAGE_HEIGHT * 0.5);
        this._videoSprite.anchor.set(0.5);
        this.addChild(this._videoSprite);

        //wait for texture to load so we can get correct width / height, then resize to fill height
        texture.baseTexture.on("loaded", () => {
            this._ratio = texture.baseTexture.width / texture.baseTexture.height;
            this.resize();
            this._source.pause();
        });
    }

    resize() {
        this._videoSprite.width = this._ratio * common.STAGE_HEIGHT;
        this._videoSprite.height = common.STAGE_HEIGHT;
    }

    play() {
        this.visible = true;
        this._source.play();
    }

    stop() {
        this.visible = false;
        this._source.currentTime = 0;
        this._source.pause();
    }

    pause() {
        this._source.pause();
    }

    destroy() {
        this._videoSprite.texture && this._videoSprite.texture.destroy(true);
        super.destroy({children: true});
    }
}