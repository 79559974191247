import {translate} from "../utils";
import common from "../common";
//import MultiStyleText from "pixi-multistyle-text";

export default class IntroCarousel extends PIXI.Container {
    constructor() {
        super();

        this._pages = [];

        for (let i = 1; i <= 4; i++) {
            this.createPage(i);
        }

        this._paginator = new Paginator(this._pages.length);
        this._paginator.y = 250;
        this.addChild(this._paginator);

    }

    setPage(index) {
        for (let i = 0; i < this._pages.length; i++) {
            const p = this._pages[i];
            p.visible = false;
        }

        this._pages[index].visible = true;

        this._paginator.setPage(index);
    }

    createPage(id) {

        var text = translate("instructions_" + id);
        if(text == "" || text == "instructions_" + id)
        {
            return;
        }
        const page = new PIXI.Container();

        const label = new PIXI.HTMLText(text,
                {
                    fontFamily: "oswaldregular",
                    fontSize: 29,
                    fill: "#000000",
                    align: "center",
                    wordWrap: true,
                    wordWrapWidth: 720
                });
        label.style.loadFont('assets/fonts/oswald-variablefont_wght-webfont.woff', { family: 'oswaldregular' }).then(() => {
            page.addChild(label);
        });
        $(label._shadow).css('zIndex', '-2000');
        /*
        const label = new PIXI.Text(translate("instructions_" + id),
            {
                    fontFamily: "oswaldregular",
                    fontSize: 32,
                    fill: "#000000",
                    align: "center",
                    wordWrap: true,
                    wordWrapWidth: 720
            });
        */
        /*
        const label = new MultiStyleText(translate("instructions_" + id),
            {
                "default": {
                    fontFamily: "oswaldregular",
                    fontSize: 32,
                    fill: "#000000",
                    align: "center",
                    wordWrap: true,
                    wordWrapWidth: 720
                },
                "r": {
                    fontFamily: "oswaldregular",
                    fontSize: 32,
                    fill: "#e20200",
                    align: "center",
                    wordWrap: true,
                    wordWrapWidth: 720
                }
            });
        */

        label.anchor.set(0.5, 0.5);
        page.label = label;

        page.y = 30;

        page.visible = false;

        this.addChild(page);
        this._pages.push(page);

    }

    get numPages()
    {
        return this._pages.length;
    }
}

class Paginator extends PIXI.Container {
    constructor(total) {
        super();

        this._pages = [];

        const spacing = 24;

        for (let i = 0; i < total; i++) {
            const p = new PIXI.Sprite(P3.assets.texture("pagination_off"));
            p.anchor.set(0.5);
            p.x = i * spacing - spacing * total * 0.5 + spacing * 0.5;
            this.addChild(p);
            this._pages.push(p);
        }
    }

    setPage(index) {
        for (let i = 0; i < this._pages.length; i++) {
            const p = this._pages[i];
            p.texture = P3.assets.texture("pagination_off");
        }

        this._pages[index].texture = P3.assets.texture("pagination_on");
    }
}