import common from "../../common";
import VideoScene from "./VideoScene";

export default class VideoScene5 extends VideoScene {
    constructor() {
        super();
    }

    init() {
        super.init();

        this._scoreStrip && this._scoreStrip.destroy();
        this._indicators && this._indicators.destroy();

        this._cache = common.videoCache[4];
        this._videos = this._cache.main;
    }

    appear() {
        common.delayg(this._tweens, 1, () => {
            this.playNextVideo();
        });
        super.appear();
    }

    destroy() {
        // clean up goes here
        super.destroy();
    }

    update() {
        super.update();
    }

    end() {
        this.signals.next.dispatch();
    }
}