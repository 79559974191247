import common from "./common";
import SplashScene from "./scenes/SplashScene";
import VideoScene1 from "./scenes/VideoScenes/VideoScene1";
import VideoScene2 from "./scenes/VideoScenes/VideoScene2";
import VideoScene3 from "./scenes/VideoScenes/VideoScene3";
import VideoScene4 from "./scenes/VideoScenes/VideoScene4";
import VideoScene5 from "./scenes/VideoScenes/VideoScene5";
import IntroScene from "./scenes/IntroScene";
import BonusScene from "./scenes/BonusScene";
import ResultsScene from "./scenes/ResultsScene";
import {cacheVideos} from "./utils";

export default class Application {
    constructor() {
        cacheVideos();
    }

    init() {
        
        //this.showVideoScene(3);
        //this.showResultsScene();
        this.showIntroScene();
    }

    showSplashScene() {
        //first cache our videos
        common.score = 0;

        common.tracking.track(new P3.TrackingDataGtagEvent("play"));

        const scene = new SplashScene();
        scene.autoAppear = false;

        scene.signals.next.add(function () {
            this.showIntroScene();
        }, this);

        this.fadeTransition(function () {
            P3.scene.show(scene);
        }, function () {
            scene.appear();
        }, this);

        return scene;
    }

    showIntroScene() {
        const scene = new IntroScene();
        scene.autoAppear = false;

        scene.signals.next.add(function () {
            this.showVideoScene(1);
        }, this);

        this.fadeTransition(function () {
            P3.scene.show(scene);
        }, function () {
            scene.appear();
        }, this);

        return scene;
    }

    showVideoScene(number) {
        let scene;
        switch (number) {
            default: {
                console.warn("unknown video number : " + number)
            }
            case 1: {
                common.foundHazards = [];
                common.score = 0;
                scene = new VideoScene1();
                scene.signals.next.add(function () {
                    this.showBonusScene(1);
                }, this);
                break;
            }
            case 2: {
                scene = new VideoScene2();
                scene.signals.next.add(function () {
                    this.showBonusScene(2);
                }, this);
                break;
            }
            case 3: {
                scene = new VideoScene3();
                scene.signals.next.add(function () {
                    this.showBonusScene(3);
                }, this);
                break;
            }
            case 4: {
                scene = new VideoScene4();
                scene.signals.next.add(function () {
                    this.showVideoScene(5);
                }, this);
                break;
            }
            case 5: {
                scene = new VideoScene5();
                scene.signals.next.add(function () {
                    this.showResultsScene();
                }, this);
                break;
            }
        }

        scene.videoNum = number;
        scene.autoAppear = false;

        scene.signals.previous.add(function () {
            common.tracking.track(new P3.TrackingDataGtagEvent("ending", {endingNumber: number}));
            this.showResultsScene(number);
        }, this);

        this.fadeTransition(function () {
            P3.scene.show(scene);
        }, function () {
            scene.appear();
        }, this);

        return scene;
    }

    showBonusScene(questionNumber = 1) {
        const scene = new BonusScene(questionNumber);
        scene.autoAppear = false;

        scene.signals.next.add(function () {
            this.showVideoScene(questionNumber + 1);
        }, this);

        this.fadeTransition(function () {
            P3.scene.show(scene);
        }, function () {
            scene.appear();
        }, this);

        return scene;
    }

    showResultsScene(endingId = -1) {
        const scene = new ResultsScene(endingId);
        scene.autoAppear = false;

        cacheVideos();

        scene.signals.next.add(function () {

            if (endingId > -1) {
                common.score = 0; //-= common.sceneScore;
                //this.showVideoScene(endingId);
            } else {
                //this.showSplashScene();
            }
            this.showIntroScene();

            common.tracking.track(new P3.TrackingDataGtagEvent("play_again"));

        }, this);

        this.fadeTransition(function () {
            P3.scene.show(scene);
        }, function () {
            scene.appear();
        }, this);

        common.tracking.track(new P3.TrackingDataGtagEvent("score", {total: common.score}));

        return scene;
    }

    fadeTransition(a, b, scope) {
        scope = scope || window;

        const transition = new P3.FadeTransition(0x0);
        common.transitions.addChild(transition);

        transition.signals.in.add(function () {
            transition.out();
            a && a.call(scope);
        }, this);

        transition.signals.out.add(function () {
            transition.destroy();
            b && b.call(scope);
        }, this);

        transition.in();
    }
}