import common from "../common";
import Scene from "./Scene";
import {translate} from "../utils";
import VideoScene from "./VideoScenes/VideoScene";
import ScoreStrip from "../ui/ScoreStrip";
import HazardWheel from "../ui/HazardWheel";
import DetailsPanel from "../ui/DetailsPanel";

export default class ResultsScene extends Scene {
    constructor(endingId) {
        super();

        this._endingId = endingId;

        this._bg = null;
        this._panel = null;
        this._logoA = null;
        this._logoB = null;
        this._fb = null;
        this._tw = null;
        this._scoreLabel = null;
        this._bodyLabel = null;

        this._totalHazards = 0;
        this._foundHazards = 0;

    }

    init() {
        super.init();

        this._bg = new PIXI.Sprite(P3.assets.texture("intro_bg"));
        this._bg.width = common.STAGE_WIDTH;
        this._bg.height = common.STAGE_HEIGHT;
        this.addChild(this._bg);

        var gradient = new PIXI.Sprite(P3.assets.texture("gradient_white_big"));
        gradient.x = common.STAGE_WIDTH / 2;
        gradient.width = common.STAGE_WIDTH;
        gradient.anchor.set(0.5, 0);
        this.addChild(gradient);

        this._strip = new PIXI.Sprite(P3.assets.texture("top_bar"));
        this._strip.width = common.STAGE_WIDTH;
        this._strip.anchor.set(0.5);
        this.addChild(this._strip);

        this._titleLabel = new PIXI.Text(translate("results"), {
            fontFamily: "oswaldregular",
            fontSize: 52,
            // fill: 0xffcf10,
            fill: 0x0,
            dropShadow: true,
            dropShadowAngle: 0.5,
            dropShadowBlur: 5,
            dropShadowColor: 0xd3d3d3
        });
        this._titleLabel.anchor.set(0.5);
        this.addChild(this._titleLabel);

        var figures = new PIXI.Sprite(P3.assets.texture("logo_hazard_hero_full"))
        figures.anchor.set(0.5);
        figures.x = common.STAGE_WIDTH / 2;
        figures.y = 230;
        this.addChild(figures);

        this._primaryHolder = new PIXI.Container();
        this._primaryHolder.y = 150;
        this.addChild(this._primaryHolder);

        this._primaryPanel = new PIXI.Sprite(PIXI.Texture.WHITE);
        this._primaryPanel.anchor.set(0.5);
        this._primaryPanel.width = 880;
        this._primaryPanel.height = 260;
        if(common.config.debug)
        {
            this._primaryPanel.alpha = 0.5;
        }
        this._primaryHolder.addChild(this._primaryPanel);

        this._scoreStrip = new ScoreStrip();
        this._scoreStrip.score = common.score;
        console.log(this._scoreStrip);
        this._primaryHolder.addChild(this._scoreStrip);

        this._hazardWheel = new HazardWheel();
        this._primaryHolder.addChild(this._hazardWheel);

        this._bodyLabel = new PIXI.Text(translate("made_it"), {
            fontFamily: "oswaldregular",
            fontSize: 25,
            fill: 0x0,
            align: "left",
            wordWrap: true,
            wordWrapWidth: 520,
            lineHeight: 30
        });
        this._bodyLabel.position.set(common.STAGE_WIDTH * 0.5 - 200, common.STAGE_HEIGHT * 0.5 - 100);
        this._bodyLabel.anchor.set(0, 0.5);
        this._bodyLabel.visible = false;
        this._primaryHolder.addChild(this._bodyLabel);

        switch (this._endingId) {
            default:
            case 1: {
                this._bodyLabel.text = translate("end_a");
                break;
            }
            case 2: {
                this._bodyLabel.text = translate("end_b");
                break;
            }
            case 3: {
                this._bodyLabel.text = translate("end_c");
                break;
            }
            case 4: {
                this._bodyLabel.text = translate("end_d");
                break;
            }
            case -1 : {
                break;
            }
        }
        
        this.initHazards();

        this._shareLabel = new PIXI.Text(translate("share"), {
            fontFamily: "oswaldregular",
            fontSize: 38,
            fill: 0x0
        });
        this._shareLabel.anchor.set(0, 0.5);
        this._shareLabel.visible = false;
        //this._primaryHolder.addChild(this._shareLabel);

        this._competionPanel = new PIXI.Sprite(P3.assets.texture("competiton_default"));
        this._competionPanel.width = this._primaryPanel.width;
        this._competionPanel.anchor.set(0.5);
        //this._primaryHolder.addChild(this._competionPanel);

        this._competionPanelTab = new PIXI.Sprite(P3.assets.texture("competittion_stripes"));
        this._competionPanelTab.anchor.set(0, 0.5);
        //this._primaryHolder.addChild(this._competionPanelTab);

        var hazardHeroLogo = P3.Button.create(
            P3.assets.texture("logo_hazard_hero")
        );
        hazardHeroLogo.x = (common.STAGE_WIDTH / 2) + (this._primaryPanel.width / 2) - 70;
        hazardHeroLogo.y = (common.STAGE_HEIGHT / 2) - (this._primaryPanel.height / 2) + 30;
        hazardHeroLogo.signals.click.add(() => {
            window.location.href = 'http://www.hazardhero.co.uk';
        });
        this._primaryHolder.addChild(hazardHeroLogo);

        this._fbButton = P3.Button.create(
            P3.assets.texture("btn_fb_out"),
            P3.assets.texture("btn_fb_over"),
            P3.assets.texture("btn_fb_down")
        );
        this._fbButton.signals.click.add(() => this.share(ResultsScene.PLATFORMS.FB));
        this._fbButton.visible = false;
        //this._primaryHolder.addChild(this._fbButton);

        this._fbButton.clickSoundName = "sfx_click";
        this._fbButton.overSoundName = "sfx_rollover";

        this._twButton = P3.Button.create(
            P3.assets.texture("btn_twitter_out"),
            P3.assets.texture("btn_twitter_over"),
            P3.assets.texture("btn_twitter_down")
        );
        this._twButton.signals.click.add(() => this.share(ResultsScene.PLATFORMS.TW));
        this._twButton.visible = false;
        //this._primaryHolder.addChild(this._twButton);

        this._twButton.clickSoundName = "sfx_click";
        this._twButton.overSoundName = "sfx_rollover";

        this._replayButton = P3.Button.create(
            P3.assets.texture("btn_regular_extralong_out"),
            P3.assets.texture("btn_regular_extralong_over"),
            P3.assets.texture("btn_regular_extralong_down")
        );
        this._replayButton.position.set(common.STAGE_WIDTH * 0.5, common.STAGE_HEIGHT * 0.8);
        this._replayButton.signals.click.once(this.onReplayButtonClick, this);
        this._replayButton.visible = false;
        this._primaryHolder.addChild(this._replayButton);

        this._replayButton.clickSoundName = "sfx_click";
        this._replayButton.overSoundName = "sfx_rollover";

        this._replayButton.label = new PIXI.Text(translate("replay_start"),//this._endingId === -1 ? "replay_start" : "replay_stage"),
            {fontFamily: "oswaldregular", fontSize: this._endingId === -1 ? 30 : 36, fill: 0x0, fontWeight: "bold"});
        this._replayButton.label.anchor.set(0.5);
        this._replayButton.label.y = 2;
        this._replayButton.animation.addChild(this._replayButton.label);

        this._competitionButton = P3.Button.create(
            P3.assets.texture("btn_competition_out"),
            P3.assets.texture("btn_competition_over"),
            P3.assets.texture("btn_competition_down")
        );
        this._competitionButton.position.set(common.STAGE_WIDTH * 0.5, common.STAGE_HEIGHT * 0.8);
        this._competitionButton.signals.click.add(this.onCompetitionClick, this);
        this._competitionButton.visible = false;
        //this._primaryHolder.addChild(this._competitionButton);

        this._competitionButton.clickSoundName = "sfx_click";
        this._competitionButton.overSoundName = "sfx_rollover";

        this._competitionButton.label = new PIXI.Text(translate("competition"),
            {fontFamily: "oswaldregular", fontSize: 36, fill: 0x0, fontWeight: "bold"});
        this._competitionButton.label.anchor.set(0.5);
        this._competitionButton.label.y = 2;
        this._competitionButton.animation.addChild(this._competitionButton.label);

        this._competitionLabel = new PIXI.Text(translate("enter_competition"), {
            fontFamily: "oswaldregular",
            fontSize: 46,
            fill: 0xffffff
        });
        this._competitionLabel.anchor.set(0, 0.5);
        this._competitionLabel.visible = false;
        //this._primaryHolder.addChild(this._competitionLabel);

        this._compareLabel = new PIXI.Text(translate("compare"), {
            fontFamily: "oswaldregular",
            fontSize: 26,
            fill: 0xffffff
        });
        this._compareLabel.anchor.set(0, 0.5);
        this._compareLabel.visible = false;
        //this._primaryHolder.addChild(this._compareLabel);

        this._medal = new PIXI.Sprite(P3.assets.texture("competition_medal"));
        this._medal.anchor.set(0.5, 0);
        this._medal.visible = false;
        this.addChild(this._medal);

        this._resultLabel = new PIXI.Text(translate("bronze"), {
            fontFamily: "oswaldregular",
            fontSize: 24,
            fill: 0xffffff,
            align: "center"
        });
        this._resultLabel.anchor.set(0.5);
        this._resultLabel.visible = false;
        this.addChild(this._resultLabel);

        this._blackStrip = new PIXI.Sprite(PIXI.Texture.WHITE);
        this._blackStrip.tint = 0x0;
        this._blackStrip.anchor.set(0.5, 1);
        this._blackStrip.width = common.STAGE_WIDTH;
        this._blackStrip.height = 110;
        this.addChild(this._blackStrip);

        this._logoFirstCar = new PIXI.Sprite(P3.assets.texture("logo_first_car"));
        this._logoFirstCar.anchor.set(0.5);
        //this.addChild(this._logoFirstCar);

        this._logoBHS = new PIXI.Sprite(P3.assets.texture("logo_bhs"));
        this._logoBHS.anchor.set(0.5);
        //this.addChild(this._logoBHS);

        this._logoPolice = new PIXI.Sprite(P3.assets.texture("logo_police"));
        this._logoPolice.anchor.set(0.5);
        //this.addChild(this._logoPolice);

        var linkLabel = new PIXI.Text(translate("hazard_hero_link_results"), {
            fontFamily: "oswaldregular",
            fontSize: 25,
            fill: 0xff0000,
            align: "left",
            wordWrap: true,
            wordWrapWidth: 520,
            lineHeight: 30
        });
        linkLabel.anchor.set(0.5);
        var link = P3.Button.create(
            PIXI.Texture.EMPTY
        );
        link.x = common.STAGE_WIDTH * 0.5;
        link.y = common.STAGE_HEIGHT - this._blackStrip.height * 0.5;
        link.animate = false;
        link.addChild(linkLabel);
        link.signals.click.add(() => {
            window.location.href = 'http://www.hazardhero.co.uk';
        });
        //this._primaryHolder.addChild(link);
        this.addChild(link);

        this._hazardWheel.signals.reveal.once(this.onReveal, this);

        $.ajax({
            type: "POST",
            data: {score:common.score, uuid:common.playerId},
            url: common.config.competition_db,
            dataType: "json",
            success: (response) => {
                console.log('score submit response: ', response);
            },
            error: (response) => {
                console.log('score submit error: ', response);
            }
        });
        /*
        this._detailsPanel = new DetailsPanel();
        this._detailsPanel.visible = false;
        this._detailsPanel.signals.submitted.add(this.onCompetitionClose, this);
        this.addChild(this._detailsPanel);
        */

    }

    resize() {
        super.resize();

        this._strip.position.set(common.STAGE_WIDTH * 0.5, this._strip.height * 0.5);
        this._titleLabel.position.set(common.STAGE_WIDTH * 0.5, this._strip.height * 0.5 + 4);

        this._scoreStrip.position.set((common.STAGE_WIDTH * 0.5) - 80, (common.STAGE_HEIGHT * 0.5) + 20);
        this._primaryPanel.position.set(common.STAGE_WIDTH * 0.5, common.STAGE_HEIGHT * 0.5 - 40);
        this._hazardWheel.position.set(common.STAGE_WIDTH * 0.5 - 320, common.STAGE_HEIGHT * 0.5 - 40);

        this._shareLabel.position.set(common.STAGE_WIDTH * 0.5 - 200, common.STAGE_HEIGHT * 0.5 + 20);
        this._fbButton.position.set(common.STAGE_WIDTH * 0.5 - 60, common.STAGE_HEIGHT * 0.5 + 20);
        this._twButton.position.set(common.STAGE_WIDTH * 0.5, common.STAGE_HEIGHT * 0.5 + 20);

        this._replayButton.position.set(common.STAGE_WIDTH * 0.5 + 240, common.STAGE_HEIGHT * 0.5 + 20);

        this._competionPanel.position.set(common.STAGE_WIDTH * 0.5, common.STAGE_HEIGHT * 0.5 + 165);
        this._competionPanelTab.position.set(common.STAGE_WIDTH * 0.5 - this._competionPanel.width * 0.5, common.STAGE_HEIGHT * 0.5 + 165);
        this._competitionButton.position.set(common.STAGE_WIDTH * 0.5 + 305, this._competionPanel.y);

        this._competitionLabel.position.set(common.STAGE_WIDTH * 0.5 - 360, this._competionPanel.y - 20);
        this._compareLabel.position.set(common.STAGE_WIDTH * 0.5 - 360, this._competionPanel.y + 20);

        this._medal.position.set(common.STAGE_WIDTH * 0.5 - this._competionPanel.width * 0.5 + 60, common.STAGE_HEIGHT * 0.5 + 165 - this._competionPanel.height * 0.5);
        this._resultLabel.position = this._competitionButton.position.clone();

        this._blackStrip.position.set(common.STAGE_WIDTH * 0.5, common.STAGE_HEIGHT);
        this._logoBHS.position.set(common.STAGE_WIDTH * 0.5 - 160, common.STAGE_HEIGHT - this._blackStrip.height * 0.5);
        this._logoFirstCar.position.set(common.STAGE_WIDTH * 0.5 + 160, common.STAGE_HEIGHT - this._blackStrip.height * 0.5);
        this._logoPolice.position.set(common.STAGE_WIDTH * 0.5, common.STAGE_HEIGHT - this._blackStrip.height * 0.5);

        //this._detailsPanel.position.set(common.STAGE_WIDTH * 0.5, common.STAGE_HEIGHT * 0.5);

        if (this._medal.visible) {
            // swap positions
            const temp = this._competitionLabel.y;
            this._competitionLabel.y = this._compareLabel.y;
            this._compareLabel.y = temp;

            this._competitionLabel.x += 40;
            this._compareLabel.x += 40;

            if (this._bronze)
                this._compareLabel.y += 20;
        }

    }

    initHazards() {

        const hazards = common.foundHazards.flat();
        const hazardData = P3.assets.json("hazard_data");

        for (const key in hazardData) {
            if (hazardData.hasOwnProperty(key)) {
                this._totalHazards += hazardData[key].length;// while we cycle object, count total hazards
            }
        }

        // set the correct texture
        for (let i = 0; i < hazards.length; i++) {
            const hazard = hazards[i];
            if (hazard && hazard.state === VideoScene.HAZARD_STATE.FOUND)
                this._foundHazards++;
        }

        if (hazards.length !== this._totalHazards)//we didn't see all the hazards
            this._failed = true;

    }

    animateIn() {
        this._hazardWheel.animateResult(this._foundHazards);
    }

    destroy() {
        // clean up goes here
        super.destroy();
    }

    update(dt = window.deltaTime) {
        super.update();
        //this._detailsPanel.update(dt);
    }

    end() {
        this.signals.next.dispatch();
    }

    share(platform) {
        switch (platform) {
            case  ResultsScene.PLATFORMS.TW: {
                window.open("https://twitter.com/home?status=" + common.config.share_url + " " + translate("share_url_copy"));
                break;
            }
            case  ResultsScene.PLATFORMS.FB: {
                window.open("https://www.facebook.com/sharer/sharer.php?u=" + common.config.share_url);
                break;
            }
        }
        common.tracking.track(new P3.TrackingDataGtagEvent("share", {platform: platform}));
    }

    onReplayButtonClick() {
        this.end();
    }

    onReveal() {
        this._bodyLabel.visible = true;
        this._shareLabel.visible = true;
        this._fbButton.visible = true;
        this._twButton.visible = true;
        this._replayButton.visible = true;
        this._competitionButton.visible = true;
        this._competitionLabel.visible = true;
        this._compareLabel.visible = true;
    }

    onCompetitionClick() {
        this._primaryHolder.visible = false;
        //this._detailsPanel.visible = true;
        this._titleLabel.text = translate("enter");
    }

    onCompetitionClose() {
        this._primaryHolder.visible = true;
        //this._detailsPanel.visible = false;
        this._titleLabel.text = translate("results");

        this._competitionButton.visible = false;
        this._competionPanelTab.visible = false;

        // turn these back on
        this._competionPanel.visible = false;
        this._competitionLabel.visible = false;
        this._compareLabel.visible = false;

        /*
        const details = this._detailsPanel.details;

        $.ajax({
            type: "POST",
            data: details,
            url: common.config.competition_db,
            dataType: "json",
            success: (response) => {
                console.log(response);

                this._competionPanel.visible = true;
                this._competitionLabel.visible = true;
                this._compareLabel.visible = true;

                this._medal.visible = true;
                this._resultLabel.visible = true;

                // swap positions
                const temp = this._competitionLabel.y;
                this._competitionLabel.y = this._compareLabel.y;
                this._compareLabel.y = temp;

                this._competitionLabel.x += 40;
                this._compareLabel.x += 40;

                // set text
                this._competitionLabel.text = translate("top");
                this._compareLabel.text = translate("congratulations");

                const percent = (response.top - response.bottom) / (common.score - response.bottom);
                console.log(percent);

                if (percent < 5) {
                    this._competionPanel.texture = P3.assets.texture("competiton_panel_gold");
                    this._competitionLabel.text = this._competitionLabel.text.replace("[v]", 5);
                    this._resultLabel.text = translate("gold");
                } else if (percent < 10) {
                    this._competionPanel.texture = P3.assets.texture("competiton_panel_silver");
                    this._competitionLabel.text = this._competitionLabel.text.replace("[v]", 10);
                    this._resultLabel.text = translate("silver");
                } else { //if (percent < 20)
                    this._competionPanel.texture = P3.assets.texture("competiton_panel_bronze");
                    this._compareLabel.y += 20;
                    this._compareLabel.style.fontSize = 48;
                    this._competitionLabel.text = "";
                    this._resultLabel.text = translate("bronze");
                    this._bronze = true;
                }

            },
            error: (response) => {
                console.log(response);
            }
        });
        */
    }
}

ResultsScene.PLATFORMS = {};
ResultsScene.PLATFORMS.FB = "fb";
ResultsScene.PLATFORMS.TW = "tw";
