/* eslint-disable */
class Brim {
    constructor(imageURI) {

        var spec = this.getSpec();

        if (!spec)
            return;

        this.height = spec[0];
        window.addEventListener('resize', this.onResize.bind(this));
        this.div = document.createElement('div');
        this.div.style.backgroundColor = "#000000";
        this.div.style.position = "absolute";
        this.div.style.top = "0";
        this.div.style.left = "0";
        this.div.style.width = "100%";
        this.div.style.height = "9999999999999999px";
        this.image = new Image();
        this.image.src = imageURI ? imageURI : 'assets/images/brim_screen.jpg';
        this.image.style.width = '100%';
        this.div.appendChild(this.image);
        this.brimMode = true;
        document.body.appendChild(this.div);

        (function () {
            var firstMove;
            document.addEventListener('touchstart', function (e) {
                if (this.brimMode) {
                    firstMove = true;
                    e.preventDefault();
                    e.returnValue = false;
                }
            }, false);
            document.addEventListener('touchmove', function (e) {
                if (this.brimMode) {
                    if (firstMove) {
                        e.preventDefault();
                        e.returnValue = false;
                    }
                    firstMove = false;
                }
            }, false);
        }());
        this.onResize();
    }

    getSpec() {
        var specs = [
            [320, 480, 2, "iPhone 4"],
            [320, 568, 2, "iPhone 5 or 5s"],
            [375, 667, 2, "iPhone 6"],
            [414, 736, 3, "iPhone 6 plus"],
            [375, 667, 3, "iPhone 7 or iPhone 8"],
            [414, 736, 3, "iPhone 7+ or iPhone 8+"],
            [375, 812, 3, "iPhone X or iPhone Xs"],
            [414, 896, 3, "iPhone Xs Max"],
            [414, 896, 3, "iPhone Xr"]
        ];
        for (var i = 0; i < specs.length; i++) {
            var spec = specs[i];
            var match = (window.screen.height == (spec[0]) && window.screen.width == (spec[1])) ||
                (window.screen.width == (spec[0]) && window.screen.height == (spec[1]));
            if (match) {
                return spec;
                break;
            }
        }
        // return [320, 480, 2, 'iPhone 4'];
        return null;
    }

    showBrim() {
        this.div.style.visibility = "visible";
        this.div.style.height = '9999999999999999px';
    }

    showGame() {
        this.div.style.visibility = 'hidden';
        // the +1 is to stop the bars from appearing on ios9
        this.div.style.height = this.height + 1 + 'px';
        window.scrollTo(0, 0);
    }

    onResize() {
        if ((window.innerHeight > window.innerWidth) || (window.innerHeight === this.height)) {
            this.showGame();
        } else {
            this.showBrim();
        }
        this.image.width = window.innerWidth;
        window.scrollTo(0, 0);
        window.setTimeout(function () {
            window.scrollTo(0, 0);
        }, 1);
    }
}

module.exports = Brim;
