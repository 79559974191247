import common from "../common";

export default class LoadingIndicator extends PIXI.Container {
    constructor() {
        super();

        const g = new PIXI.Graphics();
        g.beginFill(0xffffff);
        g.drawCircle(0, 0, 6);
        g.endFill();

        this._circleTexture = common.renderer.generateTexture(g);
        g.destroy();

        this._tweens = new TWEEN.Group();

        this._holder = new PIXI.Container();
        this.addChild(this._holder);

        this.createDots(40);
    }

    destroy(options) {
        super.destroy(options);
        this._circleTexture.destroy(true);
    }

    createDots(radius) {

        let theta = 0;
        const total = 10;

        const step = (Math.PI * 2) / total;

        for (let i = 0; i < total; i++) {
            const dot = new PIXI.Sprite(this._circleTexture);
            dot.anchor.set(0.5);
            dot.position.set(Math.cos(theta) * radius, Math.sin(theta) * radius);
            dot.scale.set(0);
            this._holder.addChild(dot);

            theta += step;
        }

    }

    begin() {
        const time = 780;

        for (let i = 0; i < this._holder.children.length; i++) {
            const dot = this._holder.children[i];
            const tween = new TWEEN.Tween(dot.scale, this._tweens)
                .to({x: 1, y: 1}, time * 0.5)
                .yoyo(true)
                .repeat(Infinity)
                .easing(TWEEN.Easing.Quadratic.InOut);

            common.delayg(this._tweens, (time / this._holder.children.length * i) / 1000, () => tween.start());
        }
    }

    update(dt) {
        this._tweens.update();
    }
}