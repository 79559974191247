export default class VideoCache {

    get all() {
        const arr = this._mainVideos.slice();
        this._succeedVideo && arr.push(this._succeedVideo);
        this._failVideo && arr.push(this._failVideo);
        this._consequenceVideo && arr.push(this._consequenceVideo);
        return arr;
    }

    get main() {
        return this._mainVideos;
    }

    get succeed() {
        return this._succeedVideo;
    }

    get fail() {
        return this._failVideo;
    }

    get consequence() {
        return this._consequenceVideo;
    }

    constructor(id) {

        this.id = id;

        this._mainVideos = [];
        this._succeedVideo = null;
        this._failVideo = null;
        this._consequenceVideo = null;

    }

    addMainVideo(video) {
        this._mainVideos.push(video)
    }

    setSucceedVideo(video) {
        this._succeedVideo = video;
    }

    setFailVideo(video) {
        this._failVideo = video;
    }

    setConsequenceVideo(video) {
        this._consequenceVideo = video;
    }
}