import common from "../../common";
import { translate } from "../../utils";
import VideoScene from "./VideoScene";

export default class VideoScene1 extends VideoScene {
    constructor() {
        super();
    }

    init() {
        super.init();

        this._cache = common.videoCache[0];

        this._videos = this._cache.main;
        this._succeedVideo = this._cache.succeed;
        this._failVideo = this._cache.fail;
        this._consequenceVideo = this._cache.consequence;

        this.tutorialPanel = new PIXI.Container();
        this.tutorialPanel.x = common.STAGE_WIDTH / 2;
        this.tutorialPanel.y = common.STAGE_HEIGHT / 2;

        this.hasClicked = false;
    }

    appear() {
        common.delayg(this._tweens, 1, () => {
            this.playNextVideo();
        });

        super.appear();
    }

    destroy() {
        // clean up goes here
        super.destroy();
    }

    update() {
        super.update();
    }

    end() {
        super.end();
    }

    onClick(event) {

        this.hasClicked = true;

        if(this.tutorialMode)
        {
            this.tutorialMode = false;
            this.removeChild(this.tutorialPanel);
            this._onPlay();
        }

        super.onClick(event);
    }

    tutorialCheck(time, start, end) {

        if(!this.hasClicked && time > end - ((end - start) * 0.2))
        {
            this.hasClicked = true;
            this._onPause();
            this.tutorialMode = true;

            var mobile = PIXI.utils.isMobile.any;

            var label = new PIXI.Text(mobile ? translate("tutorial_mobile") : translate("tutorial_desktop"), {
                fontFamily: "oswaldregular",
                fontSize: 80,
                // fill: 0xffcf10,
                fill: 0xffffff,
                dropShadow: true,
                dropShadowAngle: 0.5,
                dropShadowBlur: 5,
                dropShadowColor: 0x0
            });
            label.anchor.set(0.5);
            label.y -= 220;
            this.tutorialPanel.addChild(label);
            this.addChild(this.tutorialPanel);

            if(mobile)
            {
                var currentFrame = 0;
                var frames = ["help_hand_down", "help_hand_up"];
                var tapHand = new PIXI.Sprite(P3.assets.texture(frames[currentFrame]));
                new TWEEN.Tween(tapHand.scale, TWEEN)
                    .to({x:1, y:1}, 460)
                    .easing(TWEEN.Easing.Quadratic.InOut)
                    .yoyo(true)
                    .repeat(Infinity)
                    .onRepeat(() => {
                        currentFrame++;
                        if(currentFrame > frames.length-1)
                            currentFrame = 0;
                        tapHand.texture = P3.assets.texture(frames[currentFrame]);
                    })
                    .start();
                tapHand.anchor.set(0);
                tapHand.x = -30;
                tapHand.y = -30;
                this.tutorialPanel.addChild(tapHand);
            }
            else
            {
                var tapCursor = new PIXI.Sprite(P3.assets.texture("help_cursor"));
                new TWEEN.Tween(tapCursor.scale, TWEEN)
                    .to({x:1.2, y:1.2}, 460)
                    .easing(TWEEN.Easing.Quadratic.InOut)
                    .yoyo(true)
                    .repeat(Infinity)
                    .start();
                tapCursor.anchor.set(0);
                tapCursor.x = -30;
                tapCursor.y = -30;
                this.tutorialPanel.addChild(tapCursor);
            }
        }
    }
}