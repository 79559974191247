import {translate} from "../utils";
import common from "../common";

export default class HazardStrip extends PIXI.Container {

    set copy(value) {
        this._label.text = value;
        this.resizeIcons();
    }

    constructor() {
        super();

        this._tweens = new TWEEN.Group();

        this._strip = new PIXI.Sprite(P3.assets.texture("top_bar"));
        this._strip.width = common.STAGE_WIDTH;
        this._strip.anchor.set(0.5);
        this.addChild(this._strip);

        this._leftIcon = new PIXI.Sprite(P3.assets.texture("icon_exlamation"));
        this._leftIcon.anchor.set(0.5);
        this.addChild(this._leftIcon);

        this._rightIcon = new PIXI.Sprite(P3.assets.texture("icon_exlamation"));
        this._rightIcon.anchor.set(0.5);
        this.addChild(this._rightIcon);

        this._label = new PIXI.Text(translate("hazard_spotted"),
            {fontFamily: "oswaldregular", fontSize: 42, fill: 0x000000, fontWeight: "bold"});
        this._label.anchor.set(0.5);
        this.addChild(this._label);

        this.visible = false;

        this.resizeIcons();
    }

    update() {
        this._tweens.update();
    }

    resizeIcons() {
        this._leftIcon.x = -this._label.width * 0.5 - 90;
        this._rightIcon.x = -this._leftIcon.x;
    }

    show() {
        this.visible = true;
        // this.alpha = 0.3;
        // this.scale.set(0.3);
        /*
        new TWEEN.Tween(this, this._tweens)
            .to({alpha: 1}, 110)
            .easing(TWEEN.Easing.Quadratic.In)
            .start();

         */
        /*
        new TWEEN.Tween(this.scale, this._tweens)
            .to({x: 1, y: 1}, 140)
            .easing(TWEEN.Easing.Quadratic.Out)
            .start();
         */
    }

    hide() {
        this.visible = false;
        /*
        new TWEEN.Tween(this, this._tweens)
            .to({alpha: 0.3}, 240)
            .easing(TWEEN.Easing.Quadratic.In)
            .onComplete(() => this.visible = false)
            .start();

         */
        /*
        new TWEEN.Tween(this.scale, this._tweens)
            .to({x: 0.3, y: 0.3}, 280)
            .easing(P3.utils.Easing.Back2.In)
            .start();
         */
    }
}