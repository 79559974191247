import common from "../../common";
import VideoScene from "./VideoScene";

export default class VideoScene2 extends VideoScene {
    constructor() {
        super();
    }

    init() {
        super.init();

        this._cache = common.videoCache[1];

        this._videos = this._cache.main;
        this._succeedVideo = this._cache.succeed;
        this._failVideo = this._cache.fail;
        this._consequenceVideo = this._cache.consequence;

    }

    appear() {
        common.delayg(this._tweens, 1, () => {
            this.playNextVideo();
        });
        super.appear();
    }

    destroy() {
        // clean up goes here
        super.destroy();
    }

    update() {
        super.update();
    }

    end() {
        super.end();
    }
}