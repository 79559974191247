export default class Indicators extends PIXI.Container {
    constructor() {
        super();

        this._tweens = new TWEEN.Group();

        this._left = new PIXI.Sprite(P3.assets.texture("indicator_off"));
        this._left.anchor.set(0.5);
        this._left.x = -210;
        this.addChild(this._left);

        this._right = new PIXI.Sprite(P3.assets.texture("indicator_off"));
        this._right.anchor.set(0.5);
        this._right.scale.x = -1;
        this._right.x = 210;
        this.addChild(this._right);

        this._indicating = false;
    }

    update() {
        this._tweens.update();
    }

    start(direction) {

        if (this._indicating)
            return;

        switch (direction) {
            case Indicators.DIRECTIONS.LEFT: {
                this._left.texture = P3.assets.texture("indicator_on");
                this._right.texture = P3.assets.texture("indicator_off");

                this._left.alpha = 1;
                this._right.alpha = 1;
                this._indicateTween = new TWEEN.Tween(this._left, this._tweens)
                    .to({alpha: 0.15}, 460)
                    .easing(TWEEN.Easing.Quadratic.InOut)
                    .yoyo(true)
                    .repeat(Infinity)
                    .start();

                break;
            }
            case Indicators.DIRECTIONS.RIGHT: {
                this._left.texture = P3.assets.texture("indicator_off");
                this._right.texture = P3.assets.texture("indicator_on");

                this._left.alpha = 1;
                this._right.alpha = 1;
                this._indicateTween = new TWEEN.Tween(this._right, this._tweens)
                    .to({alpha: 0.15}, 460)
                    .easing(TWEEN.Easing.Quadratic.InOut)
                    .yoyo(true)
                    .repeat(Infinity)
                    .start();

                break;
            }
            default: {
                console.warn("unknown direction : " + direction);
                break;
            }
        }

        this._indicating = true;

    }

    stop() {
        if (!this._indicating)
            return;

        this._indicating = false;
        this._left.texture = P3.assets.texture("indicator_off");
        this._right.texture = P3.assets.texture("indicator_off");

        if (this._indicateTween) {
            this._indicateTween.stop();
            this._left.alpha = 1;
            this._right.alpha = 1;
        }
    }
}

Indicators.DIRECTIONS = {};
Indicators.DIRECTIONS.LEFT = "left";
Indicators.DIRECTIONS.RIGHT = "right";
