import common from "../../common";
import Scene from "./../Scene";
import Video from "../../utils/Video";
import QuestionPanel from "../../ui/QuestionPanel";
import HazardStrip from "../../ui/HazardStrip";
import ScoreStrip from "../../ui/ScoreStrip";
import Shake from "../../utils/Shake";
import MistakeWarning from "../../ui/MistakeWarning";
import * as Particles from "pixi-particles";
import {translate} from "../../utils";
import Indicators from "../../ui/Indicators";
import LoadingIndicator from "../../ui/LoadingIndicator";

export default class VideoScene extends Scene {

    set videoNum(value) {
        this._videoNum = value;
    }

    constructor() {
        super();

        this.tutorialMode = false;
        common.sceneScore = 0;

        let hidden;
        "undefined" != typeof document.hidden ? (hidden = "hidden",
            this.visibilityChange = "visibilitychange")     : "undefined" != typeof document.mozHidden  ? (hidden = "mozHidden",
            this.visibilityChange = "mozvisibilitychange")  : "undefined" != typeof document.msHidden   ? (hidden = "msHidden",
            this.visibilityChange = "msvisibilitychange")   : "undefined" != typeof document.webkitHidden && (hidden = "webkitHidden",
            this.visibilityChange = "webkitvisibilitychange");

        document.addEventListener(this.visibilityChange, () => {
            if(document[hidden])
            {
                if(!this._hazardMessage.visible && !this.tutorialMode)
                {
                    this._onPause();
                }
            }
            else
            {
                if(!this._hazardMessage.visible && !this.tutorialMode)
                {
                    this._onPlay();
                }
            }
        }, false);

        /*
        window.addEventListener('blur', () => {
            if(!this._hazardMessage.visible)
            {
                this._onPause();
            }
        });
        window.addEventListener('focus', () => {
            if(!this._hazardMessage.visible)
            {
                this._onPlay();
            }
        });*/

        this._questions = P3.assets.json("questions");

        this._hazardData = null;
        this._foundHazards = [];
        this._videoNum = 0;

        this._videos = [];
        this._videoIndex = -1;

        this._questionPanel = null;

        this._interactionHolder = new PIXI.Container();
        this._interactionHolder.interactive = true;
        this._interactionHolder.hitArea = new PIXI.Rectangle(0, 0, common.STAGE_WIDTH, common.STAGE_HEIGHT);
        this._interactionHolder.mousedown = this._interactionHolder.touchstart = this.onClick.bind(this);
        this.addChild(this._interactionHolder);

        this._videoHolder = new PIXI.Container();
        this.addChild(this._videoHolder);

        this._particleHolder = new PIXI.Container();
        this.addChild(this._particleHolder);

        this._uiHolder = new PIXI.Container();
        this.addChild(this._uiHolder);

        this._foundMajor = false;

        this._succeedVideo = null;
        this._failVideo = null;
        this._consequenceVideo = null;

        this._hazardStrip = new HazardStrip();
        this._hazardStrip.position.set(common.STAGE_WIDTH * 0.5, this._hazardStrip.height * 0.5);
        this.addChild(this._hazardStrip);

        this._scoreStrip = new ScoreStrip();
        this._scoreStrip.position.set(common.STAGE_WIDTH * 0.5, common.STAGE_HEIGHT - 40);
        this._scoreStrip.score = common.score;
        this._scoreStrip.visible = false;
        this.addChild(this._scoreStrip);

        this._hazardMessage = new PIXI.Container();
            var yellow = new PIXI.Sprite(PIXI.Texture.WHITE);
            yellow.tint = 0xff0000;
            yellow.width = 520;
            yellow.height = 220;
            yellow.y = 115;
            yellow.anchor.set(0.5);
            yellow.visible = false;
            this._hazardMessage.addChild(yellow);
        this._hazardMessage.yellowBorder = yellow;
            var red = new PIXI.Sprite(PIXI.Texture.WHITE);
            red.tint = 0xff0000;
            red.width = 520;
            red.height = 220;
            red.y = 115;
            red.anchor.set(0.5);
            red.visible = false;
            this._hazardMessage.addChild(red);
        this._hazardMessage.redBorder = red;
            var white = new PIXI.Sprite(PIXI.Texture.WHITE);
            white.alpha = common.config.debug ? 0.5 : 1;
            white.width = 500;
            white.height = 200;
            white.y = 115;
            white.anchor.set(0.5);
            this._hazardMessage.addChild(white);
            var icon = new PIXI.Sprite(P3.assets.texture('icon_hazard'));
            icon.anchor.set(0.5);
            this._hazardMessage.addChild(icon);
        this._hazardMessage.icon = icon;
            var text = new PIXI.Text("", {fontFamily:"oswaldregular", fontSize:32, wordWrap:true, align:'center', wordWrapWidth:480});
            text.anchor.set(0.5);
            text.y = 150;
            this._hazardMessage.addChild(text);
        this._hazardMessage.text = text;
            var particleHolder = new PIXI.Container();
            this._hazardMessage.addChild(particleHolder);
            var particle = new Particles.Emitter(particleHolder, [
                    P3.assets.texture("particle_star")],
                P3.assets.json("particle_click_star_burst")
            );
            particle.emit = false;
        this._hazardMessage.particle = particle;
        this._hazardMessage.x = common.STAGE_WIDTH / 2;
        this._hazardMessage.y = (common.STAGE_HEIGHT / 2) - 100;
        this._hazardMessage.visible = false;
        this._hazardMessage.tweens = new TWEEN.Group();
        this.addChild(this._hazardMessage);

        this._shake = new Shake();
        this._shake.setTarget(this._scoreStrip);
        this._shake.traumaFade = 1;
        this._shake.maxOffsetX = 12;
        this._shake.maxOffsetY = 12;

        this._mistakeWarning = new MistakeWarning();
        this._mistakeWarning.position.set(common.STAGE_WIDTH * 0.5, 150);
        this.addChild(this._mistakeWarning);

        this._timeSinceLastWarning = -1;
        this._warnRepeat = Infinity;

        this._debugWarning = new PIXI.Text("!", {
            fontFamily: "oswaldregular",
            fontSize: 72,
            fill: 0xffffff,
            lineJoin: "round",
            strokeThickness: 4
        });
        this._debugWarning.anchor.set(0.5);
        this._debugWarning.visible = false;
        common.config.debug && this.addChild(this._debugWarning);

        this._clickParticles = new Particles.Emitter(this._particleHolder, [
                P3.assets.texture("particle_click")],
            P3.assets.json("particle_click")
        );
        this._clickParticles.emit = false;

        this._indicators = new Indicators();
        this._indicators.position.set(common.STAGE_WIDTH * 0.5, common.STAGE_HEIGHT - 40);
        this.addChild(this._indicators);

        this._loadingIndicator = new LoadingIndicator();
        this._loadingIndicator.position.set(common.STAGE_WIDTH * 0.5, common.STAGE_HEIGHT * 0.5);
        this._loadingIndicator.visible = false;
        this.addChild(this._loadingIndicator);

        this._loadingIndicator.begin();

    }

    init() {
        super.init();
    }

    appear() {
        super.appear();
        //this.testQuestion();//TODO - uncomment for test question
    }

    animateIn(callback, scope) {
        this._scoreStrip.alpha = 0;
        new TWEEN.Tween(this._scoreStrip, this._tweens)
            .to({alpha: 1}, 940)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .delay(1100)
            .onStart(() => {
                this._scoreStrip.visible = true;
            })
            .start();
    }

    resize() {
        super.resize();
        this._videos[this._videoIndex] && this._videos[this._videoIndex].resize();
        this._debugWarning.position.set((common.STAGE_WIDTH + P3.View.width) * 0.5 - 90, common.STAGE_HEIGHT - 90);
    }

    destroy() {

        //window.removeEventListener("blur", this._onPause);
        //window.removeEventListener("focus", this._onPlay);

        // clean up goes here
        const videos = this._cache.all;
        for (let i = 0; i < videos.length; i++)
            videos[i].destroy();

        super.destroy();
    }

    update(dt = window.deltaTime) {

        this._hazardMessage.particle.update(dt);

        this._scoreStrip.update(dt);
        this._clickParticles.update(dt);

        if (this._paused)
            return;

        super.update();

        this._loadingIndicator && this._loadingIndicator.update(dt);

        if (this._videos && this._videos[this._videoIndex]) {
            this._loadingIndicator.visible = !this._videos[this._videoIndex].ready;
            this._indicators.visible = this._scoreStrip.visible = !this._loadingIndicator.visible;
        }

        // update object tweens
        this._indicators.update();
        this._hazardStrip.update();
        this._mistakeWarning.update();

        if (this._timeSinceLastWarning > 0)
            this._timeSinceLastWarning -= deltaTime;

        if (common.config.debug && P3.input.isKeyDown(P3.keys.SPACE))
            Video.skipEnd(this._videos[this._videoIndex]);

        if (this._questionPanel)
            this._questionPanel.update(dt);

        this._shake.update(dt);

        if (this._hazardData) {
            common.config.debug && (this._debugWarning.visible = false);
            for (let i = this._hazardData.length - 1; i >= 0; i--) {
                const hazard = this._hazardData[i];

                if (!hazard)
                    continue;

                let inRange = false;

                this.tutorialCheck(this._videos[this._videoIndex].currentTime, hazard.start, hazard.end);

                if (Video.inRange(this._videos[this._videoIndex].currentTime, hazard.start, hazard.end)) {
                    common.config.debug && (this._debugWarning.visible = true);
                    inRange = true;
                }

                if (inRange && !hazard.state) {
                    hazard.state = VideoScene.HAZARD_STATE.SEEN;
                    this._foundHazards[this._videoIndex][i] = hazard;//store state
                    //track hazard seen
                    common.tracking.track(new P3.TrackingDataGtagEvent("hazard", {
                        state: VideoScene.HAZARD_STATE.SEEN,
                        id: hazard.id
                    }));
                    break;
                }
            }
        }

        if (this._indicatorData && this._videos[this._videoIndex]) {

            let indicating = false;
            let direction = "";

            for (let i = this._indicatorData.length - 1; i >= 0; i--) {
                const indicate = this._indicatorData[i];

                if (!indicate)
                    continue;

                if (Video.inRange(this._videos[this._videoIndex].currentTime, indicate.start, indicate.end)) {
                    indicating = true;
                    direction = indicate.id;
                }
            }

            if (indicating) {
                this._indicators.start(direction);
            } else {
                this._indicators.stop();
            }
        }
    }

    playNextVideo() {
        //stop last video
        this._videos[this._videoIndex] && this._videos[this._videoIndex].stop();

        this._videoIndex++;
        this._hazardData = null;

        if (this._videos[this._videoIndex]) {
            this._videoHolder.addChild(this._videos[this._videoIndex]);
            this._hazardData = P3.assets.json("hazard_data")["video_" + this._videoNum + "_" + (this._videoIndex + 1)];
            this._videos[this._videoIndex].play();
            this._videos[this._videoIndex].source.onended = () => this.playNextVideo();
            this._hazardData && this._foundHazards.push(new Array(this._hazardData.length).fill(null));

            // indicator data
            this._indicatorData = P3.assets.json("indicator_data")["video_" + this._videoNum + "_" + (this._videoIndex + 1)];

            this._loadingIndicator.visible = true;
        } else {
            this.end();
        }
    }

    end() {
        //play good or bad outcome
        if (this._foundMajor) {
            this._videoHolder.addChild(this._succeedVideo);
            this._succeedVideo.play();
            this._succeedVideo.source.onended = () => this.signals.next.dispatch();
        } else {
            this._videoHolder.addChild(this._failVideo);
            this._failVideo.play();
            this._failVideo.source.onended = () => this.onConsequence();
        }
        //store found hazards in global
        common.foundHazards = common.foundHazards.concat(this._foundHazards);
    }

    testQuestion() {
        //test question data
        const questionData = {
            "question": translate("question_default"),
            "answers": [
                {
                    "copy": "Slow down in case the traffic lights turn red.",
                    "correct": 0
                },
                {
                    "copy": "Tell Keanan to stop distracting you.",
                    "correct": 1
                },
                {
                    "copy": "Do nothing.",
                    "correct": -1
                }
            ]
        };

        //show question panel
        this._questionPanel = new QuestionPanel(questionData.question, questionData.answers);
        this._questionPanel.position.set(common.STAGE_WIDTH * 0.5, common.STAGE_HEIGHT * 0.5);
        this._questionPanel.startTimer(10);
        this._uiHolder.addChild(this._questionPanel);

        this._questionPanel.signals.answered.once(this.onAnswer, this);

        this._foundMajor = true;

        this.showHazardStrip();
    }

    showHazardStrip(type) {
        this._stripDelay && this._stripDelay.stop();
        if(type == null || type == "hazard")
        {
            this._hazardStrip.copy = translate("hazard_spotted");
        }
        else
        {
            this._hazardStrip.copy = translate("risk_spotted");
        }
        this._hazardStrip.show();
        this._stripDelay = common.delayg(this._tweens, 3, () => (this._hazardStrip.hide()) && (this._stripDelay = null));
        
        this._hazardMessage.particle.emit = true;
        console.log('emit');
    }

    showLateStrip() {
        this._stripDelay && this._stripDelay.stop();
        this._hazardStrip.copy = translate("late");
        this._hazardStrip.show();
        this._stripDelay = common.delayg(this._tweens, 3, () => (this._hazardStrip.hide()) && (this._stripDelay = null));
    }

    showMistakeWarning() {
        this._stripDelay && this._stripDelay.stop();
        this._hazardStrip.copy = translate("warning");
        this._hazardStrip.show();
        this._stripDelay = common.delayg(this._tweens, 3, () => (this._hazardStrip.hide()) && (this._stripDelay = null));
    }

    showHazardIcon(id) {
        this._hazardMessage.visible = true;
        this._hazardMessage.yellowBorder.visible = false;
        this._hazardMessage.redBorder.visible = true;
        this._hazardMessage.icon.texture = P3.assets.texture('icon_hazard');
        this._hazardMessage.text.text = translate('hazard_' + id);
        this._onPause();
        common.delay(3, () => {
            this._onPlay();
            this._hazardMessage.visible = false;
            this._hazardMessage.alpha = 1;
        });
        this._hazardMessage.alpha = 0;
        new TWEEN.Tween(this._hazardMessage, TWEEN)
            .to({alpha: 1}, 310)
            .easing(TWEEN.Easing.Quadratic.Out)
            .start();
    }

    showRiskIcon(id) {
        this._hazardMessage.visible = true;
        this._hazardMessage.yellowBorder.visible = true;
        this._hazardMessage.redBorder.visible = false;
        this._hazardMessage.icon.texture = P3.assets.texture('icon_risk');
        this._hazardMessage.text.text = translate('risk_' + id);
        this._onPause();
        common.delay(3, () => {
            this._onPlay();
            this._hazardMessage.visible = false;
            this._hazardMessage.alpha = 1;
        });
        this._hazardMessage.alpha = 0;
        new TWEEN.Tween(this._hazardMessage, TWEEN)
            .to({alpha: 1}, 310)
            .easing(TWEEN.Easing.Quadratic.Out)
            .start();
    }

    onClick(event) {

        if (this._loadingIndicator.visible)
            return;

        this._particleHolder.position = event.data.getLocalPosition(this);
        this._clickParticles.resetPositionTracking();//do i want this here?
        this._clickParticles.emit = true;

        if (this._videos[this._videoIndex] && !this._videos[this._videoIndex].paused) {
            if (this._videos[this._videoIndex] && this._hazardData) {
                //check if any of hazards are in this time range
                let hazardFound = false;
                for (let i = this._hazardData.length - 1; i >= 0; i--) {
                    const hazard = this._hazardData[i];

                    if (!hazard)
                        continue;

                    if (Video.inRange(this._videos[this._videoIndex].currentTime, hazard.start, hazard.end)) {
                        //set state to found
                        hazard.state = VideoScene.HAZARD_STATE.FOUND;

                        //index directly into hazard slot
                        this._foundHazards[this._videoIndex][i] = hazard;

                        //nullify hazard
                        this._hazardData[i] = null;

                        //log hazard id
                        console.log("hazard found : " + hazard.id);

                        //track hazard found
                        common.tracking.track(new P3.TrackingDataGtagEvent("hazard", {
                            state: VideoScene.HAZARD_STATE.FOUND,
                            id: hazard.id
                        }));

                        //grab question data
                        const questionData = this._questions[hazard.id];

                        const totalTime = hazard.end - hazard.start;
                        const percent = (hazard.end - this._videos[this._videoIndex].currentTime) / totalTime;

                        if (questionData) {

                            //pause video
                            this._videos[this._videoIndex].pause();

                            //cleanup last panel
                            this._questionPanel && this._questionPanel.destroy();

                            //show question panel
                            this._questionPanel = new QuestionPanel(questionData.question, questionData.answers);
                            this._questionPanel.position.set(common.STAGE_WIDTH * 0.5, common.STAGE_HEIGHT * 0.5);
                            this._uiHolder.addChild(this._questionPanel);
                            this._questionPanel.startTimer(10);

                            this._questionPanel.signals.answered.once(this.onAnswer, this);

                            this._foundMajor = true;

                            common.score += Math.max(
                                Math.round(percent * common.config.scoring.major.max),
                                common.config.scoring.major.min);

                            common.sceneScore += Math.max(
                                Math.round(percent * common.config.scoring.major.max),
                                common.config.scoring.major.min);

                            this._scoreStrip.score = common.score;
                            this.showHazardStrip();

                        } else {

                            //no question for id means its a minor hazard
                            common.score += Math.max(
                                Math.round(percent * common.config.scoring.minor.max),
                                common.config.scoring.minor.min);

                            common.sceneScore += Math.max(
                                Math.round(percent * common.config.scoring.minor.max),
                                common.config.scoring.minor.min);

                            this._scoreStrip.score = common.score;
                            this.showHazardStrip(hazard.type);

                            if(hazard.type == 'hazard')
                                this.showHazardIcon(hazard.id);
                            else
                                this.showRiskIcon(hazard.id);

                            //highlight score
                            this._scoreStrip.correct();
                        }

                        hazardFound = true;
                        break;

                    } else if (Video.inRange(this._videos[this._videoIndex].currentTime - 2, hazard.start, hazard.end)) {

                        //if two second late
                        this.showLateStrip();

                        if(hazard.type == 'hazard')
                            this.showHazardIcon(hazard.id);
                        else
                            this.showRiskIcon(hazard.id);

                        hazardFound = true;//we can count this as finding a hazard
                        break;

                    }
                }

                //penalise clicking outside of window
                if (!hazardFound) {

                    common.score -= common.config.scoring.penalty;
                    if (common.score < 0)
                        common.score = 0;

                    console.log("no hazard found");
                    console.log("major score : " + common.score);
                    this._scoreStrip.score = common.score;

                    //shake it!
                    this._shake.trauma += 0.5;
                    //highlight score
                    this._scoreStrip.wrong();

                    //if we are inside warn time, give user a warning
                    if (this._timeSinceLastWarning === -1 || this._timeSinceLastWarning < this._warnRepeat) {
                        this._timeSinceLastWarning = this._warnRepeat;
                        this.showMistakeWarning();
                    }

                }
            }
        }
    }

    onAnswer(button) {

        //cleanup last panel and resume video
        this._questionPanel && common.delayg(this._tweens, 2.5, () => {
            this._questionPanel.destroy();
            this._videos[this._videoIndex].play()
        });

        //score user for answer
        if (button.correct < 0) {
            //failed answer
            this._foundMajor = false;
        } else {
            common.score += button.value;
            common.sceneScore += button.value;
            this._scoreStrip.score = common.score;
        }

    }

    onConsequence() {
        if (this._consequenceVideo) {
            this._scoreStrip.hide();
            this._indicators.visible = false;
            this._videoHolder.addChild(this._consequenceVideo);
            this._consequenceVideo.play();
            this._consequenceVideo.source.onended = () => this.signals.previous.dispatch();
        }
    }

    _onPause() {

        this._paused = true;

        // pause playing video and store its index
        const videos = this._cache.all;
        this._videoResumeIndex = -1;

        for (let i = 0; i < videos.length; i++) {
            if (!videos[i].paused) {
                videos[i].pause();
                this._videoResumeIndex = i;
                break;
            }
        }
    }

    _onPlay() {

        this._paused = false;

        // resume video that got paused
        const videos = this._cache.all;

        if (this._videoResumeIndex > -1 && videos[this._videoResumeIndex])
            videos[this._videoResumeIndex].play();
    }

    tutorialCheck(time, start, end) {
        
    }
}

VideoScene.HAZARD_STATE = {};
VideoScene.HAZARD_STATE.SEEN = "seen";
VideoScene.HAZARD_STATE.FOUND = "found";