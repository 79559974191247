import common from "../common";

export default class Scene extends P3.Scene {
    constructor() {
        super();

        this._tweens = new TWEEN.Group();

    }

    destroy() {
        this._tweens.removeAll();
        super.destroy({children: true});
    }

    resize() {
        this.x = (P3.View.width - common.STAGE_WIDTH) * 0.5;
    }

    update() {
        this._tweens.update();
    }

    get tweens() {
        return this._tweens;
    }
}