import BasePanel from "./BasePanel";

export default class IntroPanel extends BasePanel {
    constructor() {
        super("panel_results", {leftWidth: 32, topHeight: 32, rightWidth: 32, bottomHeight: 32, fontSize: 64});
        this.updateDimensions(800, 560);

        this._titleLabel.style = {
            fontFamily: "oswaldregular",
            fontSize: 52,
            fill: 0x0,
        };
        this._titleLabel.dirty = true;

        var logo = new PIXI.Sprite(P3.texture("logo_hazard_hero"));
        logo.x -= 390;
        logo.y -= 260;
        this.addChild(logo);

    }
}