import QuestionPanel from "./QuestionPanel";

export default class BonusQuestionPanel extends QuestionPanel {

    get questionHolder() {
        return this._questionHolder;
    }

    constructor(question, answers) {
        super(question, answers);
        this._timerBar.y = -this._slice.height * 0.5 + 133;
        //this._logo.visible = false;
        this._logo.x = this._slice.x - (this._slice.width/2) + 10;
        this._logo.y = this._slice.y - (this._slice.height/2) + 10;
    }

    updateDimensions(width, height) {
        super.updateDimensions(width + 90, height + 40);
        this._titleLabel.y = -this._slice.height * 0.5 + 64;
        this._questionHolder && (this._questionHolder.y = this._titleLabel.y + this._questionHolder.height * 0.5 - 22);

        this._titleLabel.style.wordWrapWidth = this._slice.width - 250;

        if (this._titleLabel.height > 100) //todo, reduce font size instead
            this._titleLabel.scale.set(0.9);
    }
}