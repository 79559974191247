import Application from "./Application";
import common from "./common";
import PreloaderScene from "./scenes/PreloaderScene";
import Brim from "./brim";
import screenfull from "screenfull";

export default class Main {
    /**
     * @param {number} [width=1024]
     * @param {number} [height=768]
     */
    constructor(width = 1024, height = 768) {
        this._width = width;
        this._height = height;
        this._app = null;
        this._preloader = null;
        this._printLoadProgress = true;
        this._fontLoaded = false;
    }

    init() {
        P3.assets.audioFileTypes = ["ogg", "mp3"];
        P3.settings.WARN_TEXTURE_DIVISIBLE_BY_2 = false;
        P3.audio.preloadAudio = true;
        P3.input.refocus = false;

        PIXI.settings.RETINA_PREFIX = /_(?=[^_]*$)(.+)x/;
        PIXI.settings.MIPMAP_TEXTURES = false;
        PIXI.settings.STRICT_TEXTURE_CACHE = true;
        
        console.log(P3.VERSION);
        console.log(P3);
        console.log(TWEEN);
        common.tracking = new P3.Tracking(new P3.TrackingModuleGtag('UA-160662228-1'));

        typeof TweenMax !== "undefined" && (TweenMax.defaultOverwrite = "none");

        const options = {
            width: this._width,
            height: this._height,
            holderId: "game",
            portrait: false,
            rotateImageUrl: "./assets/images/rotate.jpg",
            rotateImageColor: "#000000",
            aspectRatioFillHeight: true
        };

        const view = new P3.View(options);
        view.signals.ready.once(function (canvas) {
            const options = {};
            options.view = canvas;
            options.transparent = false;
            options.antialias = false;
            options.preserveDrawingBuffer = false;
            options.resolution = 1;

            const stage = new PIXI.Container();
            common.stage = stage;

            P3.input.init(stage, canvas);

            /*
            if (P3.utils.isCocoonJS()) {
                stage.scale.x = window.innerHeight / this._height;
                stage.scale.y = window.innerHeight / this._height;
            }*/

            var ua = window.navigator.userAgent;
            var isIE = /MSIE|Trident/.test(ua);

            let renderer;
            if (PIXI.Renderer) {

                if (isIE) {
                    renderer = new PIXI.CanvasRenderer(Object.assign(options, {
                        width: this._width, height: this._height
                    }));
                } else {
                    renderer = new PIXI.Renderer(Object.assign(options, {
                        width: this._width, height: this._height
                    }));
                }
                /*
                 renderer = new PIXI.Renderer(Object.assign(options, {
                     width: this._width, height: this._height
                 }));

                 */

            } else {
                renderer = /*!P3.utils.isCocoonJS() ?
                    PIXI.autoDetectRenderer(this._width, this._height, options) :*/
                    new PIXI.WebGLRenderer(window.innerWidth, window.innerHeight, options);
            }
            renderer.backgroundColor = 0x0;
            common.renderer = renderer;

            common.stage.addChild(P3.scene.view);

            const transitions = new PIXI.Container();
            common.stage.addChild(transitions);
            common.transitions = transitions;

            // deprecated
            //window.lasttweentime = 0;
            //window.tweendt = 0;

            PIXI.Ticker.shared.add((deltaTime) => {
                window.deltaTime = deltaTime / PIXI.settings.TARGET_FPMS / 1000;

                // deprecated
                //const currentTime = TWEEN.now();
                //window.tweendt = currentTime - window.lasttweentime;
                //window.lasttweentime = currentTime;

                P3.scene.update(window.deltaTime);
                P3.input.update();

                TWEEN.update();// comment this line out if you are using TweenMax

                renderer.render(stage);
            });

            if (PIXI.utils.isMobile.apple.device) {
                new Brim("assets/images/brim.jpg");
            }

            /*
            if (screenfull.isEnabled) {
                setTimeout(() => {
                    screenfull.request();
                }, 100);
            }*/

            this.loadPreAssets();

            var urlParams = new URLSearchParams(window.location.search);
            if(urlParams.get('PlayerID'))
            {
                common.playerId = urlParams.get('PlayerID');
            }
            if(urlParams.get('id'))
            {
                common.playerId = urlParams.get('id');
            }

        }, this);

        view.signals.resize.add(this.onCanvasResize, this);
        common.view = view;

        this.loadConfig(function () {
            view.init()
        }, this);    // we load the config before we do anything
    }

    loadConfig(callback, scope) {
        scope = scope || window;
        P3.assets.addFiles([{name: "config", url: "config" + ".json"}], "./");
        P3.assets.signals.complete.once(function () {
            common.config = P3.assets.json("config");
            callback && callback.call(scope);
        }, this);
        P3.assets.load();
    }

    loadPreAssets() {
        const files = [{name: "strings", url: "data/strings" + ".json"}];
        if (files.length) {
            P3.assets.addFiles(files, "./assets/");
            P3.assets.signals.complete.once(this.loadAssets, this);
            P3.assets.load();
        } else {
            this.loadAssets();
        }
    }

    loadAssets() {

        common.strings = P3.assets.json("strings");

        const files = [
            // {name: "top_bar_tile", url: "images/top_bar_tile" + ".png"},
            //bg
            {name: "splash_bg", url: "images/bg_splash_1x" + ".jpg"},
            {name: "intro_bg", url: "images/bg_question_1x" + ".jpg"},
            //sheets
            {name: "game_assets_1x", url: "images/game_assets_1x" + ".json"},
            {name: "question_default_full", url: "images/question_default_full" + ".png"},
            {name: "question_correct_full", url: "images/question_correct_full" + ".png"},
            {name: "question_incorrect_full", url: "images/question_incorrect_full" + ".png"},
            {name: "panel_results", url: "images/panel_results" + ".png"},
            {name: "top_bar", url: "images/top_bar" + ".png"},
            {name: "gradient_white_big", url: "images/gradient_white_big" + ".png"},
            //data
            {name: "hazard_data", url: "data/hazard_data" + ".json"},
            {name: "indicator_data", url: "data/indicator_data" + ".json"},
            {name: "questions", url: "data/questions" + ".json"},
            //particles
            {name: "particle_click", url: "particles/particle_click" + ".json"},
            {name: "particle_click_star_burst", url: "particles/particle_click_star_burst" + ".json"},
            {name: "particle_correct_answer", url: "particles/particle_correct_answer" + ".json"},
            //audio
            {name: "sfx_click", url: "audio/sfx/sfx_click" + P3.assets.audioFileExt},
            {name: "sfx_rollover", url: "audio/sfx/sfx_rollover" + P3.assets.audioFileExt},
            {name: "sfx_negative", url: "audio/sfx/sfx_negative" + P3.assets.audioFileExt},
            {name: "sfx_positive", url: "audio/sfx/sfx_positive" + P3.assets.audioFileExt},
        ];

        if (files.length) {
            this._preloader = new PreloaderScene();
            P3.scene.show(this._preloader);

            P3.assets.addFiles(files, "./assets/");
            P3.assets.signals.progress.add(this.onLoadingProgress, this);
            P3.assets.signals.complete.once(this.onLoadingCompleted, this);
            P3.assets.load();

            var dummyLabel = new PIXI.HTMLText('test text',
            {
                fontFamily: "oswaldregular",
                fontSize: 32,
                fill: "#000000",
                align: "center",
                wordWrap: true,
                wordWrapWidth: 720
            });
            dummyLabel.style.loadFont('assets/fonts/oswald-variablefont_wght-webfont.woff', { family: 'oswaldregular' }).then(() => {
                this._fontLoaded = true;
                if(this._preloader.loaded == 1)
                {
                    setTimeout(() => this.start(), 1000);
                }
            });
        } else {
            this.start();
        }
    }

    start() {
        this._app = new Application();
        this._app.init();
        console.log(PIXI);
    }

    onLoadingProgress(event) {
        this._preloader && (this._preloader.loaded = event.progress / 100);
        this._printLoadProgress && console.log("loading:", Math.floor(event.progress));
    }

    onLoadingCompleted() {
        this._preloader && (this._preloader.loaded = 1);
        if(this._fontLoaded)
        {
            setTimeout(() => this.start(), 1000);
        }
    }

    onCanvasResize(correct) {
        if (correct) {
            common.renderer.resize(P3.View.width, P3.View.height);
            if (P3.scene) {
                P3.scene.resize();

                for (let i = 0; i < common.transitions.children.length; ++i) {
                    const transition = common.transitions.children[i];
                    transition.resize && transition.resize();
                }
            }
        }
    }
}

P3.assets.texture = function(name, extension)
{
    extension = extension === "" ? "" : "." + (extension || "png");

    let texture;
    try {
        texture = PIXI.Texture.from(name + extension);
    } catch (error) {
        if (this._resources[name]) {
            texture = this._resources[name].texture;
        } else {
            console.warn("texture not found :", name);
            texture = this._fallbackTexture;
        }
    }

    return texture;
}
