import common from "../common";
import Video from "./Video";
import VideoCache from "./VideoCache";

/**
 * Returns translated string for given key
 * @param key
 * @returns {*|string}
 */
export function translate(key) {
    let str = key || "";
    if (str.length > 0) {
        const split = str.split(".");
        split.push(common.config.language || "en");
        let i, length, t = common.strings;
        for (i = 0, length = split.length; i < length; ++i) {
            t = t[split[i]];
            if (!t) break;
        }
        i === split.length && (str = t);
    }
    return str;
}

/**
 * Cache videos ahead of time
 */
export function cacheVideos() {
    //cleanup any existing videos
    for (let i = 0; i < common.videoCache.length; i++) {
        const videos = common.videoCache[i].all;
        for (let j = 0; j < videos.length; j++) {
            videos[j].destroy();
        }
    }
    common.videoCache = [];

    let cache;

    //cache video scene 1
    cache = new VideoCache(1);

    cache.addMainVideo(createVideo("bhs_1-1"));
    cache.addMainVideo(createVideo("bhs_1-2"));

    cache.setSucceedVideo(createVideo("bhs_1-3"));
    cache.setFailVideo(createVideo("bhs_1-4"));
    cache.setConsequenceVideo(createVideo("bhs_1-5"));

    common.videoCache.push(cache);

    //cache video scene 2
    cache = new VideoCache(2);

    cache.addMainVideo(createVideo("bhs_2-1"));

    cache.setSucceedVideo(createVideo("bhs_2-2"));
    cache.setFailVideo(createVideo("bhs_2-3"));
    cache.setConsequenceVideo(createVideo("bhs_2-4"));

    common.videoCache.push(cache);

    //cache video scene 3
    cache = new VideoCache(3);

    cache.addMainVideo(createVideo("bhs_3-1"));

    cache.setSucceedVideo(createVideo("bhs_3-2"));
    cache.setFailVideo(createVideo("bhs_3-3"));
    cache.setConsequenceVideo(createVideo("bhs_3-4"));

    common.videoCache.push(cache);

    //cache video scene 4
    cache = new VideoCache(4);

    cache.addMainVideo(createVideo("bhs_4-1"));

    cache.setSucceedVideo(createVideo("bhs_4-2"));
    cache.setFailVideo(createVideo("bhs_4-3"));
    cache.setConsequenceVideo(createVideo("bhs_4-4"));

    common.videoCache.push(cache);

    //cache video scene 5
    cache = new VideoCache(5);

    cache.addMainVideo(createVideo("bhs_5"));

    common.videoCache.push(cache);
}

/**
 * Creates new video
 * @param fileName
 * @param ext
 * @returns {Video}
 */
export function createVideo(fileName, ext = "mp4") {
    return new Video(fileName, ext);
}

/**
 * Queues videos (queue on user event)
 * @param sceneId
 */
export function queueVideos(sceneId) {
    if (!common.videoCache[sceneId - 1])
        return;

    const cache = common.videoCache[sceneId - 1];
    for (let i = 0; i < cache.main.length; i++) {
        cache.main[i].source.play();
        cache.main[i].pause();
    }
    cache.succeed && (cache.succeed.source.play() && cache.succeed.pause());
    cache.fail && (cache.fail.source.play() && cache.fail.pause());
    cache.consequence && (cache.consequence.source.play() && cache.consequence.pause());
}

/**
 * Creates a button with a label
 * @param type
 * @param labelCopy
 * @returns {*}
 */
export function createLabelButton(type = "regular", labelCopy = "") {

    const button = P3.Button.create(
        P3.assets.texture("btn_" + type + "_out"),
        P3.assets.texture("btn_" + type + "_over"),
        P3.assets.texture("btn_" + type + "_down")
    );

    button.clickSoundName = "sfx_click";
    button.overSoundName = "sfx_rollover";

    button.label = new PIXI.Text(labelCopy,
        {fontFamily: "oswaldregular", fontSize: 36, fill: 0x0, fontWeight: "bold"});
    button.label.anchor.set(0.5);
    button.label.y = 2;
    button.animation.addChild(button.label);

    return button;

}

//array fill polyfill
(function () {
    if (!Array.prototype.fill) {
        Object.defineProperty(Array.prototype, 'fill', {
            value: function (value) {

                // Steps 1-2.
                if (this == null) {
                    throw new TypeError('this is null or not defined');
                }

                var O = Object(this);

                // Steps 3-5.
                var len = O.length >>> 0;

                // Steps 6-7.
                var start = arguments[1];
                var relativeStart = start >> 0;

                // Step 8.
                var k = relativeStart < 0 ?
                    Math.max(len + relativeStart, 0) :
                    Math.min(relativeStart, len);

                // Steps 9-10.
                var end = arguments[2];
                var relativeEnd = end === undefined ?
                    len : end >> 0;

                // Step 11.
                var finalValue = relativeEnd < 0 ?
                    Math.max(len + relativeEnd, 0) :
                    Math.min(relativeEnd, len);

                // Step 12.
                while (k < finalValue) {
                    O[k] = value;
                    k++;
                }

                // Step 13.
                return O;
            }
        });
    }

})();

// array flat polyfill from - https://codepen.io/chungguo/pen/xvXeMR
(function () {
    if (!Array.prototype.flat) {
        Object.defineProperty(Array.prototype, 'flat', {
            value: function () {
                return this.reduce((acc, cur, index) => {
                    return Array.isArray(cur) ? acc.concat(flat(cur)) : acc.concat(cur);
                }, []);
            }
        });
    }
})();

/*
(function () {
    TWEEN.Group.prototype.time = 0;
    TWEEN.Group.prototype.update = function (time, preserve) {
        this.time += window.tweendt;

        var tweenIds = Object.keys(this._tweens);
        if (tweenIds.length === 0) {
            return false;
        }
        time = time !== undefined ? time : this.time;
        while (tweenIds.length > 0) {
            this._tweensAddedDuringUpdate = {};

            for (var i = 0; i < tweenIds.length; i++) {
                var tween = this._tweens[tweenIds[i]];
                if (tween && tween.update(time) === false) {
                    tween._isPlaying = false;
                    if (!preserve) {
                        delete this._tweens[tweenIds[i]];
                    }
                }
            }
            tweenIds = Object.keys(this._tweensAddedDuringUpdate);
        }

        return true;
    };

    TWEEN.Tween.prototype.start = function (time) {
        this._group.add(this);

        this._isPlaying = true;

        this._onStartCallbackFired = false;

        this._startTime = time !== undefined ? typeof time === 'string' ? this._group.time + parseFloat(time) : time : this._group.time;
        this._startTime += this._delayTime;

        for (var property in this._valuesEnd) {
            if (this._valuesEnd[property] instanceof Array) {
                if (this._valuesEnd[property].length === 0) {
                    continue;
                }
                this._valuesEnd[property] = [this._object[property]].concat(this._valuesEnd[property]);

            }
            if (this._object[property] === undefined) {
                continue;
            }

            this._valuesStart[property] = this._object[property];

            if ((this._valuesStart[property] instanceof Array) === false) {
                this._valuesStart[property] *= 1.0;
            }
            this._valuesStartRepeat[property] = this._valuesStart[property] || 0;
        }

        return this;
    };
})();
 */