import common from "../common";
import Scene from "./Scene";

export default class PreloaderScene extends Scene {
    constructor() {
        super();

        this.loaded = 0;

        this._barWidth = 420;
    }

    init() {
        super.init();

        //ensure font is loaded
        this.addChild(new PIXI.Text("", {fontFamily: "oswaldregular", fontSize: 58}));

        this._bg = new PIXI.Sprite(PIXI.Texture.BLACK);
        this._bg.width = common.STAGE_WIDTH;
        this._bg.height = common.STAGE_HEIGHT;
        this.addChild(this._bg);

        this._bar = new PIXI.Sprite(PIXI.Texture.WHITE);
        this._bar.anchor.set(0, 0.5);
        this._bar.height = 6;
        this._bar.width = 0;
        this._bar.position.set(common.STAGE_WIDTH * 0.5 - this._barWidth * 0.5, common.STAGE_HEIGHT * 0.65);
        //this._bar.tint = 0x0;
        this.addChild(this._bar);

    }

    destroy() {
        // clean up goes here
        super.destroy();
    }

    update() {
        this._bar.width = this.loaded * this._barWidth;
    }
}