//import CountDown from "./CountDown";
import BasePanel from "./BasePanel";
import common from "../common";
import * as Particles from "pixi-particles";

export default class QuestionPanel extends BasePanel {
    constructor(question, answers) {
        super("panel_question", {leftWidth: 32, topHeight: 150, rightWidth: 32, bottomHeight: 32, fontSize: 38});
        this.updateDimensions(860, 460);

        this.signals = {};
        this.signals.answered = new P3.utils.MiniSignal();

        this._failButton = null;

        this._titleLabel.text = question;

        this._buttons = [];

        /*
        this._countDown = new CountDown();
        this._countDown.position.set(this._slice.width * 0.5,
            -this._slice.height * 0.5);
        this.addChild(this._countDown);

        this._countDown.signals.end.once(this.onTimerEnd, this);
         */

        this._logo = new PIXI.Sprite(P3.texture("logo_hazard_hero"));
        this._logo.x -= 400;
        this._logo.y -= 220;
        this.addChild(this._logo);

        this._questionHolder = new PIXI.Container();
        this.addChild(this._questionHolder);

        this._particleHolder = new PIXI.Container();
        this._questionHolder.addChild(this._particleHolder);

        this._createAnswers(answers);

        this._time = 0;
        this._timeLength = 10;

        this._timerBar = new PIXI.Sprite(P3.assets.texture("bar_counter"));
        this._timerBar.anchor.set(0, 0.5);
        this.addChild(this._timerBar);

        this._timerBar.position.set(-this._slice.width * 0.5, -97);
        this._timerBar.width = 0;

        this._emitterLeft = new Particles.Emitter(this._particleHolder, [
                P3.assets.texture("particle_star")],
            P3.assets.json("particle_correct_answer")
        );
        this._emitterLeft.rotate(180);
        this._emitterLeft.updateOwnerPos(-350, 0);
        this._emitterLeft.emit = false;

        this._emitterRight = new Particles.Emitter(this._particleHolder, [
                P3.assets.texture("particle_star")],
            P3.assets.json("particle_correct_answer")
        );
        this._emitterRight.updateOwnerPos(400, 0);
        this._emitterRight.emit = false;

        this._completed = false;
    }

    startTimer(time) {
        //this._countDown.setTimer(time);
        this._time = 0;
    }

    update(dt) {
        this._emitterLeft.update(dt);
        this._emitterRight.update(dt);
        //this._countDown.update(dt);
        this._time += dt;

        if (this._time < this._timeLength)
            !this._completed && (this._timerBar.width = this._time / this._timeLength * this._slice.width);
        else
            !this._completed && this.onTimerEnd();
    }

    destroy() {
        this.signals.answered.detachAll();
        super.destroy();
    }

    _createAnswers(answers) {
        //find longest copy so we can use for button length
        let copy = "";
        for (let i = 0; i < answers.length; i++) {
            if (answers[i].copy.length > copy.length)
                copy = answers[i].copy;
        }

        const label = new PIXI.Text(copy, {fontFamily: "oswaldregular", fontSize: 32});
        const width = (label.width + 40 > 120) ? label.width + 40 : 120;
        const spacing = 100;

        if ((label.height + spacing) * answers.length > this._slice.height) {
            this.updateDimensions(this._slice.width, (label.height + spacing) * answers.length);//the height plus 90 for the title strip
        }

        if (width > this._slice.width) {
            this.updateDimensions(width + 40, this._slice.height);//the width plus some padding
        }

        //the height of the questions + spacing + panel title bar
        //const yStart = -(label.height * (answers.length) + (answers.length) * spacing) * 0.5 + 150;

        const failButtons = [];

        const alphabet = ["A", "B", "C", "D", "E"];

        for (let i = 0; i < answers.length; i++) {
            const button = P3.Button.create(PIXI.Texture.EMPTY);
            button.clickSoundName = "sfx_click";
            button.overSoundName = "sfx_rollover";

            const panel = new PIXI.Sprite(P3.assets.texture("question_default_full"));
            panel.anchor.set(0.5);
            // panel.width = width;
            // panel.height = 80;
            // panel.pivot.set(panel.width * 0.5, panel.height * 0.5);
            button.animation.addChild(panel);
            button.y = i * spacing + 40;
            button.panel = panel;

            const label = new PIXI.Text(answers[i].copy,
                {fontFamily: "oswaldregular", fontSize: 28, fill: 0x0, fontWeight: "bold"});
            label.anchor.set(0, 0.5);
            label.x = -panel.width * 0.5 + 100;
            button.animation.addChild(label);

            button.id = 0;
            button.correct = parseInt(answers[i].correct);
            button.value = (button.correct + 1) * common.config.scoring.hazard_question;

            button.signals.click.once(this.onAnswerClick, this);

            button.rewardPanel = new PIXI.Sprite(P3.assets.texture("reward"));
            button.rewardPanel.anchor.set(0, 0.5);
            button.rewardPanel.x = button.width * 0.5 - button.rewardPanel.width * 0.5;
            button.animation.addChild(button.rewardPanel);

            button.rewardPanel.label = new PIXI.Text(button.value,
                {fontFamily: "oswaldregular", fontSize: 28, fill: 0x0, fontWeight: "bold"});
            button.rewardPanel.label.anchor.set(0.5);
            button.rewardPanel.label.x = button.rewardPanel.width * 0.5 + 22;
            button.rewardPanel.addChild(button.rewardPanel.label);

            button.rewardPanel.visible = false;

            const alphabetLabel = new PIXI.Text(alphabet[i],
                {fontFamily: "oswaldregular", fontSize: 28, fill: 0x0, fontWeight: "bold"});
            alphabetLabel.anchor.set(0.5);
            alphabetLabel.x = -panel.width * 0.5 + 40;
            button.animation.addChild(alphabetLabel);

            this._questionHolder.addChild(button);

            if (button.correct < 0)
                failButtons.push(button);

            this._buttons.push(button);
        }

        this._questionHolder.y = -this._questionHolder.height * 0.5 + this._titleLabel.height + 30;

        if (failButtons.length > 0)
            this._failButton = P3.utils.selectRandom(failButtons);

        this.updateDimensions(this._slice.width, this._slice.height);
    }

    onAnswerClick(button) {

        button.panel.texture = button.correct < 0 ?
            P3.assets.texture("question_incorrect_full") : P3.assets.texture("question_correct_full");

        this.signals.answered.dispatch(button);

        button.rewardPanel.scale.set(0);
        button.rewardPanel.visible = true;
        button.rewardPanel.label.scale.set(0);

        const a = new TWEEN.Tween(button.rewardPanel.scale)
            .to({x: 1, y: 1}, 220)
            .easing(TWEEN.Easing.Back.Out);

        const b = new TWEEN.Tween(button.rewardPanel.label.scale)
            .to({x: 1, y: 1}, 220)
            .easing(TWEEN.Easing.Back.Out);

        a.chain(b);
        a.start();

        // disable buttons
        for (let i = 0; i < this._buttons.length; i++) {
            this._buttons[i].enabled = false;
            this._buttons[i].animate = false;
        }

        if (button.correct >= 0) {
            this._particleHolder.position = button.position.clone();
            this._emitterLeft.emit = true;
            this._emitterRight.emit = true;
        }

        this._completed = true;

    }

    onTimerEnd() {
        this._failButton && (this._failButton.panel.texture = P3.assets.texture("question_incorrect_full"));
        common.delay(1, () => this.signals.answered.dispatch(this._failButton));

        this._time = Number.NEGATIVE_INFINITY;
    }

    updateDimensions(width, height) {
        super.updateDimensions(width, height);
        //this._countDown && this._countDown.position.set(this._slice.width * 0.5, -this._slice.height * 0.5);

        this._titleLabel.style.wordWrap = true;
        this._titleLabel.style.wordWrapWidth = this._slice.width - 90;
        this._titleLabel.style.align = "center";
        this._titleLabel.updateText();
    }
}