export default class TickBox extends PIXI.Sprite {

    get on() {
        return this._tick.visible;
    }

    set on(value) {
        this._tick.visible = value;
    }

    constructor(backTexture, tickTexture) {
        super(backTexture);
        this.anchor.set(0.5);

        this._tick = new PIXI.Sprite(tickTexture);
        this._tick.anchor.set(0.5);
        this.addChild(this._tick);
        this._tick.visible = false;

        this.interactive = true;
        this.buttonMode = true;
        this.mousedown = this.touchstart = () => this._onDown();
    }

    _onDown() {
        this.on = !this.on;
    }
}